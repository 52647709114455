import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModalActionConfig } from '@components/action-modal/action-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@services/modal.service';
import { DpUtilsService } from 'digitup-lib/dist/digitup-lib';
import * as pako from 'pako';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {

  constructor(private utils: DpUtilsService,
    private translate: TranslateService,
    private modalService: ModalService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map(event => {
        if (event instanceof HttpResponse) {
          if (event.body?.type === 'Buffer') {
            const decompressedBody = pako.inflate(event.body.data, { to: 'string' });
            return event.clone({ body: JSON.parse(decompressedBody) });
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log('error', error);
        if (error.status === 499) {
          const modalConfig: ModalActionConfig = {
            title: error.error.type ? this.translate.instant(`errorMessages.general_error.${error.error.type}`) : 'Algun problema',
            subtitle: error.error.message
          };
          modalConfig.buttons = [{
            id: 'read',
            cssClass: 'secondary',
            text: 'general.close'
          }];
          this.modalService.openActionModal(modalConfig, true);
          return throwError(error);
        } else {
          if (error?.error?.message?.includes('auth/')) {
            this.utils.showToast({ message: this.translate.instant(`errorMessages.firebase.${error.error.message}`), cssClass: 'danger' });
          } else if (error?.error?.message) {
            this.utils.showToast({ message: error.error.message, cssClass: 'danger' });
          } else if (error?.error?.error) {
            this.utils.showToast({ message: error.error.error, cssClass: 'danger' });
          } else {
            this.utils.showToast({ message: this.translate.instant('auth.error.internal_error'), cssClass: 'danger' });
          }
        }

        return throwError(error);
      })
    );
  }

}
