import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Clipboard } from '@capacitor/clipboard';
import { User } from '@interfaces/user';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from '@services/utils.service';
import { DpUtilsService } from 'digitup-lib/dist/digitup-lib';

export type arrayInputId = 'rate';
export type InputMode = 'input' | 'textarea' | 'phone';

export interface ButtonType {
  id: string;
  text: string;
  cssClass: string;
  icon?: string;
  action?: boolean;
  marginTop?: string;
}

export interface InputType {
  id: string;
  type?: string;
  inputMode: InputMode;
  defaultValue: string;
  placeholder: string;
  icon?: string;
  validators?: any[];
  disabled: boolean;
}

export interface InputArrayType {
  id: arrayInputId;
  values: any[];
  defaultValue: any[];
  validators?: any[];
}


export interface ModalActionConfig {
  title?: string;
  subtitle?: string;
  buttons?: ButtonType[];
  inputs?: InputType[];
  arrayInputs?: InputArrayType[];
  user?: User;
}

export interface GetDataEvent {
  button?: {
    id: string
  },
  input?: {
    [key: string]: string
  }
}

@Component({
  selector: 'app-action-modal',
  templateUrl: './action-modal.component.html',
  styleUrls: ['./action-modal.component.scss'],
})
export class ActionModalComponent implements OnInit {

  @Input() config: ModalActionConfig = {};

  form!: FormGroup;

  prefix = '+34';
  phone = '';

  constructor(
    private modalController: ModalController,
    private utils: DpUtilsService,
    private utilsLocal: UtilsService,
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
    if (this.config.inputs && this.config.inputs.length || this.config.arrayInputs && this.config.arrayInputs.length) {
      const formControlsConfig: any = {};
      if (this.config.inputs && this.config.inputs.length) {
        this.config.inputs.forEach(field => {
          if (field.validators) {
            formControlsConfig[field.id] = new FormControl({ value: field.defaultValue, disabled: field.disabled }, field.validators);
          }
          else {
            formControlsConfig[field.id] = new FormControl({ value: field.defaultValue, disabled: field.disabled }, []);
          }
        })
      }
      if (this.config.arrayInputs && this.config.arrayInputs.length) {
        this.config.arrayInputs.forEach(field => {
          if (field.validators) {
            formControlsConfig[field.id] = new FormControl(field.defaultValue, field.validators);
          }
          else {
            formControlsConfig[field.id] = new FormControl(field.defaultValue, []);
          }
        })
      }
      this.form = new FormGroup(formControlsConfig);
    }
  }

  setValueForFormControl(controlId: string, value: any, values: any[]) {
    console.log('controlId', controlId);
    const control = this.form.get(controlId) as FormControl;
    console.log('control', control);

    if (control) {
      const filteredValues = values.filter(elem => elem <= value);
      console.log('filteredValues', filteredValues);

      control.setValue(filteredValues);
    }
  }

  async getData(button: ButtonType) {
    if (this.config.inputs?.[0]?.inputMode === 'phone') {
      if (button.id === 'yes') {
        const phone = this.prefix + this.phone;
        if (!this.phone || !this.utilsLocal.isValidPhone(phone)) {
          await this.utils.showToast({
            message: this.translate.instant('errorMessages.general_error.invalid_phone'), cssClass: 'danger'
          });
        } else {
          await this.modalController.dismiss({ phone });
        }
      } else {
        await this.close();
      }
    } else {
      if (this.config.inputs && this.config.inputs.length || this.config.arrayInputs && this.config.arrayInputs.length) {
        let data: GetDataEvent = {
          button: {
            id: button.id
          },
          input: {}
        }
        if (this.config.inputs && this.config.inputs.length) {
          this.config.inputs.forEach(field => {
            if (data.input) [
              data.input[field.id] = this.form.value[field.id]
            ]
          })
        }
        if (this.config.arrayInputs && this.config.arrayInputs.length) {
          this.config.arrayInputs.forEach(field => {
            if (data.input) [
              data.input[field.id] = this.form.value[field.id]
            ]
          })
        }
        await this.modalController.dismiss(data)
      } else {
        let data: GetDataEvent = {
          button: {
            id: button.id
          }
        }
        await this.modalController.dismiss(data)
      }

    }
  }

  async getInputEvent(inputId: string, text?: string) {
    console.log('getInputEvent inputId', inputId);
    console.log('getInputEvent text', text);
    if (inputId === 'phoneNumber') {
      await Clipboard.write({
        string: text
      });
      this.utils.showToast({ message: this.translate.instant('journey.number_copied') });
    }
  }

  async close() {
    await this.modalController.dismiss(null)
  }

  async selectCountryCode() {
    const result = await this.utilsLocal.selectCountryCode(this.config.user?.language || 'es', this.prefix);

    if (result) {
      this.prefix = result;
    }
  }

  getCountryCodeName(prefix: string) {
    return this.utilsLocal.getCountryCodeName(prefix);
  }

}
