<ion-content>
  <ion-icon class="close_button" name="dp-cross" (click)="dismiss()"></ion-icon>
  <div class="checkbox-container">
    <ion-checkbox [(ngModel)]="general"></ion-checkbox>
    <div class="label" [innerHTML]="'terms.general' | translate"></div>
  </div>
  <div class="checkbox-container">
    <ion-checkbox [(ngModel)]="comunication"></ion-checkbox>
    <div class="label" [innerHTML]="'terms.comunication' | translate"></div>
  </div>
  <div class="buttons">
    <ion-button fill="clear" class="general register-btn" (click)="dismiss(true)" appClickVibrate
      translate>general.accept_all</ion-button>
    <ion-button fill="clear" class="general register-btn" (click)="dismiss(true, false)" [disabled]="!general"
      appClickVibrate translate>general.accept</ion-button>
  </div>
</ion-content>