import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Journey } from '@interfaces/journey';
import { Socket } from 'ngx-socket-io';
import { JourneyWatchService } from './journey-watch.service';
import { DpUtilsService } from 'digitup-lib/dist/digitup-lib';
import { TranslateService } from '@ngx-translate/core';
import { ModalController } from '@ionic/angular';
import * as pako from 'pako';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  currentSocketId = '';
  listnerInited = false;

  constructor(
    private socket: Socket,
    private auth: AngularFireAuth,
    private journeyWatchService: JourneyWatchService,
    private utils: DpUtilsService,
    private translate: TranslateService,
    private modalController: ModalController
  ) {
    this.initSocket();
  }

  private initSocket() {
    this.auth.onAuthStateChanged(async user => {
      if (user) {
        const result = this.socket.connect();
        if (result.id) {
          this.registerUid(user.uid);
          this.currentSocketId = result.id;
        }

        if (!this.listnerInited) {
          this.listnerInited = true;
          this.socket.fromEvent('connect').subscribe(() => {
            console.log('Connected to socket server! Socket ID:', this.socket.ioSocket.id);
            this.currentSocketId = this.socket.ioSocket.id;
            this.registerUid(user.uid);
          });

          this.listnerJourney();
        }
      } else {
        this.socket.disconnect();
        this.currentSocketId = '';
      }
    });
  }

  private registerUid(uid: string) {
    this.socket.emit('set-uid', { uid });
  }

  private listnerJourney() {
    console.log('INIT listnerJourney');
    this.socket.on('journey', async (journey: any) => {
      try {
        journey = this.decompressData(journey);
      } catch (error) { }
      console.log('!!! JOURNEY !!!', journey);
      if (journey) {
        switch (journey.status) {
          case 'PENDING':
            this.journeyWatchService.nextJourney(journey)
            break;
          case 'RESERVE_WAITING':
            this.utils.showToast({
              message: this.translate.instant('activity.messages.RESERVE_WAITING')
            });
            break;
          case 'CANCELED_BY_USER':
            await this.journeyWatchService.destroyJourney();
            break;
          case 'CANCELED_BY_DRIVER':
            this.utils.showToast({
              message: this.translate.instant('activity.messages.CANCELED_BY_DRIVER'),
              cssClass: 'warning',
              duration: 6000
            });
            await this.journeyWatchService.destroyJourney();
            await this.dismiss();
            break;
          case 'CANCELED_BY_MANAGER':
            this.utils.showToast({
              message: this.translate.instant('activity.messages.CANCELED_BY_DRIVER'),
              cssClass: 'warning',
              duration: 6000
            });
            await this.journeyWatchService.destroyJourney();
            await this.dismiss();
            break;
          case 'ERROR':
            this.utils.showToast({
              message: this.translate.instant('activity.messages.ERROR'),
              cssClass: 'warning',
              duration: 6000
            });
            await this.journeyWatchService.destroyJourney();
            await this.dismiss();
            break;
          default:
            // RATING_AND_PAYMENT, CLOSED, CANCELED_BY_USER, CANCELED_BY_DRIVER, CANCEL Without action
            this.journeyWatchService.nextJourney(journey)
            break;
        }
      }
    });
  }

  private async dismiss() {
    if (await this.modalController.getTop()) {
      await this.modalController.dismiss();
    }
    if (await this.modalController.getTop()) {
      await this.modalController.dismiss();
    }
  }

  /**
 * Decompresses data received via socket. If the data is in ArrayBuffer format,
 * it decompresses and parses it into a GeneralSocket object.
 * If decompression fails, it returns the original data cast as GeneralSocket.
 *
 * @param {ArrayBuffer | GeneralSocket} socketInfo - The data to decompress, which can be an ArrayBuffer or already a GeneralSocket object.
 * @returns {GeneralSocket} - The decompressed and parsed data as a GeneralSocket object, or the original data if an error occurs.
 */
  private decompressData(socketInfo: ArrayBuffer) {
    // Check if the input is an instance of ArrayBuffer before attempting to decompress
    if (socketInfo instanceof ArrayBuffer) {
      try {
        const decompressed = pako.inflate(new Uint8Array(socketInfo), { to: 'string' });
        const decompressParsed = JSON.parse(decompressed);
        return decompressParsed;
      } catch (error) {
        console.error("Failed to decompress or parse the data:", error);
        // Optionally, throw the error to be handled by the caller
        console.error('Decompression or parsing failed.');
        throw new Error('Decompression or parsing failed.');
      }
    } else {
      console.error('is GeneralSocket');
      throw new Error('is GeneralSocket');
    }

    // If not ArrayBuffer or if there was an error, return the input as GeneralSocket
    // return socketInfo as GeneralSocket;
  }
}

