import { Injectable } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';

import { filter } from 'rxjs/operators';

// import { UtilsService } from '../utils/utils.service';

// import { StorageService } from '../storage/storage.service';
// import { Device } from '@capacitor/device';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { Device } from '@capacitor/device';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  // currentUser: User;
  isInit = false;
  started = false;
  constructor(
    private router: Router,
    // private utils: UtilsService,
    // private storage: StorageService
  ) {
  }

  async start(autorized = false) {
    // const statusCookies = await this.storage.getStatusCookies();
    // console.log('statusCookies', statusCookies);

    // const enableAnalitics = this.currentUser && this.currentUser.enableCookies;
    // if (statusCookies || autorized) {
    if (autorized) {
      await this.initFb();
      console.log('ENABLE ANALITICAS');
    }

    if (!this.started) {
      this.started = true;
      this.router.events.pipe(
        filter((e: (Event | any)) => e instanceof NavigationEnd)
      ).subscribe((e: RouterEvent) => {
        this.setScreenName(e.url);
      });
    }
  }

  async initFb() {
    console.log('Init firebase cookies');
    const device = await Device.getInfo();
    if (device.platform === 'web') {
      const resp: any = await FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);
      if (resp) {
        this.isInit = true;
      }
    } else {
      this.isInit = true;
    }
  }

  logEvent(name = '', params = {}) {
    if (this.isInit) {
      FirebaseAnalytics.logEvent({ name, params });
    }
  }

  setScreenName(screenName: string) {
    if (this.isInit) {
      FirebaseAnalytics.setScreenName({ screenName });
    }
  }

  sendEventByType(name: AnalyticType, params: ParamType = {}) {
    if (this.isInit) {
      FirebaseAnalytics.logEvent({ name, params });
    }
  }
}

export interface ParamType {
  currentUserId?: string;
  type?: string;
  id?: string;
}

export type AnalyticType =
  'create_request' |
  'search_professionals' |
  'add_favorites' |
  'delete_favorites' |
  'edit_profile' |
  'create_profile' |
  'confirm_request' |
  'cancel_request' |
  'delete_request' |
  'pay_request' |
  'login' |
  'register'


