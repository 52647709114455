<ng-container
  *ngIf="config.inputs && config.inputs.length || config.arrayInputs && config.arrayInputs.length; else divContent">
  <!-- Отображать контент в форме -->
  <!-- <form class="container" [formGroup]="form" [class.horizontal]="orientation === 'horizontal'">
    <ng-container [ngTemplateOutlet]="content" [ngTemplateOutletContext]="{ form: form }"></ng-container>
  </form> -->
  <form class="container" [formGroup]="form">
    <ng-container [ngTemplateOutlet]="content" [ngTemplateOutletContext]="{ form: form }"></ng-container>
  </form>
</ng-container>
<ng-template #divContent>
  <!-- Отображать контент в div -->
  <!-- <div class="container" [class.horizontal]="orientation === 'horizontal'">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </div> -->
  <div class="container">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </div>
</ng-template>

<ng-template #content let-form=form>
  <div class="title-container">
    <div class="title" *ngIf="config.title"> {{ config.title | translate }} </div>
    <div class="close" (click)="close()" appClickVibrate>
      <ion-icon name="dp-close"></ion-icon>
    </div>
  </div>
  <ng-container *ngIf="config.subtitle">
    <div class="subtitle-container">
      <div class="subtitle" [innerHTML]="config.subtitle | translate"></div>
    </div>
  </ng-container>
  <ng-container *ngIf="config.arrayInputs && config.arrayInputs.length">
    <div class="input-container">
      <ng-container *ngFor="let arrayInput of config.arrayInputs">
        <ng-container *ngIf="arrayInput.id === 'rate'">
          <div class="rate-container">
            <ion-icon *ngFor="let value of arrayInput.values"
              [name]="form.get(arrayInput.id) && form.get(arrayInput.id).value.includes(value) ? 'dp-star-fill' : 'dp-star'"
              (click)="setValueForFormControl(arrayInput.id, value, arrayInput.values)" appClickVibrate>
            </ion-icon>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="config.inputs && config.inputs.length">
    <div class="input-container">
      <ng-container *ngFor="let input of config.inputs">
        <ng-container *ngIf="['input', 'textarea'].includes(input.inputMode); else isPhone">
          <ng-container *ngIf="input.inputMode === 'input'; else textareaInput">
            <ion-item class="input-item" lines="none">
              <ion-input [type]="input.type" [formControl]="form.get(input.id)"
                [placeholder]="input.placeholder | translate"
                [attr.disabled]="input.disabled ? 'disabled' : null"></ion-input>
              <ion-icon class="left_icon" *ngIf="input.icon" [name]="input.icon"
                (click)="getInputEvent(input.id, form.get(input.id).value)" appClickVibrate></ion-icon>
            </ion-item>
          </ng-container>
          <ng-template #textareaInput>
            <ion-item class="input-item" lines="none" class="textarea">
              <ion-textarea [formControl]="form.get(input.id)" [attr.disabled]="input.disabled ? 'disabled' : null"
                [placeholder]="input.placeholder | translate"></ion-textarea>
              <ion-icon slot="end" *ngIf="input.icon" [name]="input.icon" (click)="getInputEvent(input.id)"></ion-icon>
            </ion-item>
          </ng-template>
        </ng-container>

        <ng-template #isPhone>
          <div class="one_line_country_code">
            <div class="country_code" (click)="selectCountryCode()">
              {{ getCountryCodeName(prefix) }} ({{ prefix }})
            </div>
            <div class="phone_number">
              <ion-input [placeholder]="input.placeholder | translate" type="number" inputmode="tel" class="general"
                [(ngModel)]="phone"></ion-input>
            </div>
          </div>
        </ng-template>

      </ng-container>
    </div>
  </ng-container>
  <!-- <ng-container *ngIf="config.inputs && config.inputs.length">
    <div class="input-container">
      <ng-container *ngFor="let input of config.inputs">
        <ion-item class="input" lines="none" class="input">
          <ion-textarea [formControlName]="input.id" [type]="input.type" [attr.disabled]="input.disabled ? input.disabled : null" [placeholder]="input.placeholder | translate"></ion-textarea>
          <ion-icon slot="end" *ngIf="input.icon" [name]="input.icon" (click)="getInputEvent(input.id)"></ion-icon>
        </ion-item>
      </ng-container>
    </div>
  </ng-container> -->
  <div class="btn-container"
    [class.no-margin]="config.inputs && config.inputs.length || config.arrayInputs && config.arrayInputs.length">
    <ng-container *ngFor="let button of config.buttons">
      <ng-container
        *ngIf="button.action && config.inputs && config.inputs.length || config.arrayInputs && config.arrayInputs.length; else elseTemplete">
        <ion-button fill="clear" [className]="button.cssClass" (click)="getData(button)" appClickVibrate
          [disabled]="form.invalid" [style.marginTop]="button.marginTop">
          <ion-icon *ngIf="button.icon" [name]="button.icon"></ion-icon>
          {{ button.text | translate }}
        </ion-button>
      </ng-container>
      <ng-template #elseTemplete>
        <ion-button fill="clear" [className]="button.cssClass" (click)="getData(button)" appClickVibrate
          [style.marginTop]="button.marginTop">
          <ion-icon *ngIf="button.icon" [name]="button.icon"></ion-icon>
          {{ button.text | translate }}
        </ion-button>
      </ng-template>
    </ng-container>
  </div>
</ng-template>