import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { NavController } from '@ionic/angular';
import { RestService } from '@services/rest.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(private nav: NavController, private auth: AngularFireAuth, private rest: RestService) { }

  async canActivateFn(): Promise<boolean> {
    return new Promise(async resolve => {
      await this.auth.onAuthStateChanged(async user => {
        console.log('user', user);
        if (user) {
          resolve(true);
        } else {
          resolve(false);
          this.nav.navigateRoot('pre-login');
        }
      });
    });
  }
}
