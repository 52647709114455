import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit {
  general = false;
  comunication = false;

  constructor(
    private modalC: ModalController
  ) { }

  async ngOnInit() {
  }

  dismiss(accept = false, all = true) {
    let sendData = null;
    if (accept) {
      if (all) {
        this.general = true;
        this.comunication = true;
      }
      sendData = {
        general: this.general,
        comunication: this.comunication
      }
    }
    this.modalC.dismiss(sendData);
  }

}
