import { Validators } from '@angular/forms';
import { ModalActionConfig } from '@components/action-modal/action-modal.component';
import { RadioModalData } from '@components/google-map/google-map.component';
import { RadioModal } from '@components/radio-modal/radio-modal.component';
import { ModalTextareaConfig } from '@components/textarea-modal/textarea-modal.component';
// Modal action config
export const actionModalConfirm: ModalActionConfig = {
    title: 'action_modal.confirm.title',
    subtitle: '',
    buttons: [
        {
            id: 'yes',
            cssClass: 'cancel',
            text: 'action_modal.confirm.button_yes'
        },
        {
            id: 'no',
            cssClass: 'secondary',
            text: 'action_modal.confirm.button_no'
        }
    ]
}

export const actionModalDeleteSuggestion: ModalActionConfig = {
    title: 'action_modal.delete_sugestion.title',
    subtitle: '',
    buttons: [
        {
            id: 'yes',
            cssClass: 'cancel',
            text: 'general.yes'
        },
        {
            id: 'no',
            cssClass: 'secondary',
            text: 'general.no'
        }
    ]
}

export const actionModalReadMore: ModalActionConfig = {
    buttons: [
        {
            id: 'read',
            cssClass: 'secondary',
            text: 'action_modal.cancel_account.button_no'
        }
    ]
}

export const actionModalCancelAccount: ModalActionConfig = {
    title: 'action_modal.cancel_account.title',
    subtitle: 'action_modal.cancel_account.subtitle',
    buttons: [
        {
            id: 'yes',
            cssClass: 'cancel',
            text: 'action_modal.cancel_account.button_yes'
        },
        {
            id: 'no',
            cssClass: 'secondary',
            text: 'action_modal.cancel_account.button_no'
        }
    ]
}

export const actionModalDownloadApp: ModalActionConfig = {
    title: 'action_modal.download_app.title',
    subtitle: 'action_modal.download_app.subtitle',
    buttons: [
        {
            id: 'yes',
            cssClass: 'success',
            text: 'action_modal.download_app.download'
        },
        {
            id: 'no',
            cssClass: 'secondary',
            text: 'general.later'
        }
    ]
}

export const actionModalPhoneOption: ModalActionConfig = {
    title: 'action_modal.phone_option.title',
    subtitle: 'action_modal.phone_option.message',
    buttons: [
        {
            id: 'yes',
            cssClass: 'success',
            text: 'general.add'
        }
    ],
    inputs: [
        {
            id: 'phone',
            inputMode: 'phone',
            defaultValue: '',
            placeholder: 'action_modal.phone_option.placeholder',
            disabled: false
        }
    ]
}

export const actionModalLogout: ModalActionConfig = {
    title: 'action_modal.logout.title',
    buttons: [
        {
            id: 'yes',
            cssClass: 'cancel',
            text: 'action_modal.logout.button_yes'
        },
        {
            id: 'no',
            cssClass: 'secondary',
            text: 'action_modal.logout.button_no'
        }
    ]
}

export const actionModalNoHaveAccount: ModalActionConfig = {
    title: 'action_modal.no_have_account.title',
    subtitle: 'action_modal.no_have_account.subtitle',
    buttons: [
        {
            id: 'understood',
            cssClass: 'general',
            text: 'action_modal.no_have_account.button_understood'
        }
    ]
}

export const actionModalChooseAnAccount: ModalActionConfig = {
    title: 'action_modal.no_have_account.title',
    buttons: [
        // {
        //     id: 'new',
        //     cssClass: 'secondary',
        //     text: 'action_modal.no_have_account.button_new_account',
        // },
    ]
}

export const actionModalСompleteService: ModalActionConfig = {
    title: 'action_modal.complete_service.title',
    subtitle: 'action_modal.complete_service.subtitle',
    buttons: [
        {
            id: 'back',
            cssClass: 'secondary',
            text: 'action_modal.complete_service.button_back'
        },
        {
            id: 'ok',
            cssClass: 'general',
            text: 'action_modal.complete_service.button_ok'
        }
    ]
}

export const actionModalHowGetOffer: ModalActionConfig = {
    title: 'action_modal.how_to_get_offer.title',
    subtitle: 'action_modal.how_to_get_offer.subtitle',
    buttons: [
        {
            id: 'offer_someone',
            cssClass: 'secondary',
            text: 'action_modal.how_to_get_offer.button_offer_someone',
            icon: 'dp-user-once'
        },
        {
            id: 'offer_community',
            cssClass: 'general',
            text: 'action_modal.how_to_get_offer.button_offer_community',
            icon: 'dp-user-more'
        }
    ]
}

export const actionModalWantReturnService: ModalActionConfig = {
    title: 'action_modal.want_return_service.title',
    subtitle: 'action_modal.want_return_service.subtitle',
    buttons: [
        {
            id: 'back',
            cssClass: 'secondary',
            text: 'action_modal.want_return_service.button_back'
        },
        {
            id: 'offer_community',
            cssClass: 'general',
            text: 'action_modal.want_return_service.button_yes_return',
        }
    ]
}

export const actionModalReturnedService: ModalActionConfig = {
    title: 'action_modal.returned_service.title',
    subtitle: 'action_modal.returned_service.subtitle',
    buttons: [
        {
            id: 'back',
            cssClass: 'general',
            text: 'action_modal.returned_service.button_accept'
        },
    ]
}

export const actionModalCallCustomer: ModalActionConfig = {
    title: 'action_modal.call_customer.title',
    subtitle: 'action_modal.call_customer.subtitle',
    inputs: [
        {
            id: 'phoneNumber',
            inputMode: 'input',
            defaultValue: '+44 671 46 78 34',
            type: 'text',
            icon: 'dp-copy',
            placeholder: '',
            // validators: [Validators.required],
            disabled: true
        }
    ],
    buttons: [
        {
            id: 'whatsapp',
            cssClass: 'general',
            text: 'action_modal.call_customer.button_whats_app',
            icon: 'dp-whatsapp',
            action: true
        },
        {
            id: 'back',
            cssClass: 'secondary',
            text: 'action_modal.want_return_service.button_back'
        },
    ]
}

export const actionModalCallCustomerNoPhoneNumber: ModalActionConfig = {
    title: 'action_modal.call_customer_no_phone_number.title',
    subtitle: 'action_modal.call_customer_no_phone_number.subtitle',
    buttons: [
        {
            id: 'back',
            cssClass: 'secondary',
            text: 'action_modal.call_customer_no_phone_number.button_back'
        },
    ]
}

export const actionModalRejectClient: ModalActionConfig = {
    title: 'action_modal.reject_client.title',
    subtitle: 'action_modal.reject_client.subtitle',
    buttons: [
        {
            id: 'back',
            cssClass: 'cancel',
            text: 'action_modal.reject_client.button_yes_reject',
            icon: 'dp-user-reject'
        },
        {
            id: 'back',
            cssClass: 'secondary',
            text: 'action_modal.reject_client.button_cancel'
        }
    ]
}

export const actionModalConfirmClientAbsence: ModalActionConfig = {
    title: 'action_modal.confirm_client_absence_client.title',
    subtitle: 'action_modal.confirm_client_absence_client.subtitle',
    buttons: [
        {
            id: 'back',
            cssClass: 'cancel',
            text: 'action_modal.confirm_client_absence_client.button_yes_reject',
            icon: 'dp-close'
        },
        {
            id: 'back',
            cssClass: 'secondary',
            text: 'action_modal.confirm_client_absence_client.button_cancel'
        }
    ]
}

export const actionModalCleanChat: ModalActionConfig = {
    title: 'action_modal.clean_chat.title',
    subtitle: 'action_modal.clean_chat.subtitle',
    buttons: [
        {
            id: 'back',
            cssClass: 'cancel',
            text: 'action_modal.clean_chat.button_yes_reject',
            icon: 'dp-close'
        },
        {
            id: 'back',
            cssClass: 'secondary',
            text: 'action_modal.clean_chat.button_cancel'
        }
    ]
}

export const actionModalChatOptions: ModalActionConfig = {
    title: 'action_modal.options_chat.title',
    buttons: [
        {
            id: 'delete',
            cssClass: 'cancel',
            text: 'action_modal.options_chat.button_delete',
        },
    ]
}

export const actionModalOptions: ModalActionConfig = {
    title: 'action_modal.options.title',
    buttons: [
        {
            id: 'back',
            cssClass: 'cancel',
            text: 'action_modal.options.button_delete_chat',
        },
    ]
}

// Languages array
export const languages: Array<string> = ['ES', 'EN'];

// export const radioModalLicensesData: RadioModalData[] = [
//     {
//         id: '1',
//         text: 'Todas las zonas',
//     },
//     {
//         id: '2',
//         text: 'P01  Alcúdia',
//     },
//     {
//         id: '3',
//         text: 'P02  Magic',
//     },
//     {
//         id: '4',
//         text: 'P03  P. Blanco',
//     },
//     {
//         id: '5',
//         text: 'P04  Bellevue',
//     },
//     {
//         id: '6',
//         text: 'P05  Eden',
//     },
//     {
//         id: '7',
//         text: 'P05  Eden',
//     },
//     {
//         id: '8',
//         text: 'P05  Eden',
//     },
//     {
//         id: '9',
//         text: 'P05  Eden',
//     }
// ]

// export const radioModalReservationTypeData: RadioModalData[] = [
//     {
//         id: '1',
//         text: 'Rueda',
//     },
//     {
//         id: '2',
//         text: 'Privadas',
//     },
//     {
//         id: '3',
//         text: 'Paradas',
//     },
//     {
//         id: '4',
//         text: 'Carretera',
//     },
//     {
//         id: '5',
//         text: 'Agencia',
//     },
// ]

// export const radioModalSelectZoneData: RadioModalData[] = [
//     {
//         id: '1',
//         text: 'Todas las zonas',
//     },
//     {
//         id: '2',
//         text: 'P01  Alcúdia',
//     },
//     {
//         id: '3',
//         text: 'P02  Magic',
//     },
//     {
//         id: '4',
//         text: 'P03  P. Blanco',
//     },
//     {
//         id: '5',
//         text: 'P04  Bellevue',
//     },
//     {
//         id: '6',
//         text: 'P05  Eden',
//     },
// ]

// export const radioModalSelectMapZoneData: RadioModalData[] = [
//     {
//         id: '1',
//         text: 'Zonas',
//     },
//     {
//         id: '2',
//         text: 'P01  Alcúdia',
//     },
//     {
//         id: '3',
//         text: 'P02  Magic',
//     },
//     {
//         id: '4',
//         text: 'P03  P. Blanco',
//     },
//     {
//         id: '5',
//         text: 'P04  Bellevue',
//     },
//     {
//         id: '6',
//         text: 'P05  Eden',
//     },
// ]

// export const radioModalSelectStatusData: RadioModalData[] = [
//     {
//         id: '1',
//         text: 'Estado',
//     },
//     {
//         id: '2',
//         text: 'Gratis',
//     },
//     {
//         id: '3',
//         text: 'Ocupado',
//     },
// ]

export const radioModalEnabledTemplateData: RadioModalData[] = [
    {
        id: '1',
        text: 'Parada vacia',
        template: 'La parada [Nombre parada] está vacia y no hay ningún vehiculo en él.'
    },
    {
        id: '2',
        text: 'Avisar inicio de guardia',
        template: 'La parada [Nombre parada] está vacia y no hay ningún vehiculo en él.'
    },
    {
        id: '3',
        text: 'Advertencia sobre un área peligrosa',
        template: 'La parada [Nombre parada] está vacia y no hay ningún vehiculo en él.'
    },
    {
        id: '4',
        text: 'Información sobre tarifas y regulaciones',
        template: 'La parada [Nombre parada] está vacia y no hay ningún vehiculo en él.'
    },
    {
        id: '5',
        text: 'Compartir una recomendación de cliente',
        template: 'La parada [Nombre parada] está vacia y no hay ningún vehiculo en él.'
    },
    {
        id: '6',
        text: 'Alerta de evento especial',
        template: 'La parada [Nombre parada] está vacia y no hay ningún vehiculo en él.'
    },
    {
        id: '7',
        text: 'Recordatorio mantenimiento vehículos',
        template: 'La parada [Nombre parada] está vacia y no hay ningún vehiculo en él.'
    },
]

// // Radio Modal
// export const radioModalSelectZone: RadioModal = {
//     title: 'radio_modal.select_zone.title',
//     selectId: '',
//     selectActiveType: 'check',
//     data: radioModalSelectZoneData
// }

// export const radioModalSelectMapZone: RadioModal = {
//     title: 'radio_modal.select_zone.title',
//     selectId: '',
//     selectActiveType: 'check',
//     data: radioModalSelectMapZoneData
// }

// export const radioModalSelectStatus: RadioModal = {
//     title: 'radio_modal.select_status.title',
//     selectId: '',
//     selectActiveType: 'check',
//     data: radioModalSelectStatusData
// }

export const radioModalEnabledTemplate: RadioModal = {
    title: 'radio_modal.enabled_template.title',
    selectActiveType: 'bold',
    type: 'other',
    data: radioModalEnabledTemplateData
}

// export const radioModalReservationType: RadioModal = {
//     title: 'radio_modal.reservation_type.title',
//     selectActiveType: 'bold',
//     data: radioModalReservationTypeData
// }

// export const radioModalLicenses: RadioModal = {
//     title: 'radio_modal.licenses.title',
//     selectActiveType: 'check',
//     data: radioModalLicensesData,
//     selectId: '',
//     inputs: [
//         {
//             id: 'search',
//             defaultValue: '',
//             type: 'text',
//             leftIcon: 'dp-search',
//             placeholder: 'radio_modal.licenses.search_placehoder',
//             // validators: [Validators.required],
//             disabled: false
//         }
//     ],
// }

// export const radioModalModifyDestination: RadioModal = {
//     title: 'radio_modal.modify_destination.title',
//     selectActiveType: 'bold',
//     data: [],
//     inputs: [
//         {
//             id: 'search',
//             defaultValue: '',
//             type: 'text',
//             leftIcon: 'dp-search',
//             // rightIcon: 'dp-microphone',
//             placeholder: 'radio_modal.modify_destination.search_placehoder',
//             // validators: [Validators.required],
//             disabled: false
//         }
//     ],
// }

// export const textareaModalIncident: ModalTextareaConfig = {
//     title: 'textarea_modal.incidents.title',
//     inputs: [
//         {
//             id: 'textarea',
//             defaultValue: '',
//             type: 'text',
//             // icon: 'dp-microphone',
//             placeholder: 'textarea_modal.incidents.input_placeholder',
//             validators: [Validators.required],
//             disabled: false
//         }
//     ],
//     buttons: [
//         {
//             id: 'yes',
//             cssClass: 'general',
//             text: 'textarea_modal.incidents.button_send',
//             action: true
//         },
//     ]
// }

// export const textareaModalMessages: ModalTextareaConfig = {
//     title: 'textarea_modal.message.title',
//     inputs: [
//         {
//             id: 'textarea',
//             defaultValue: '',
//             type: 'text',
//             // icon: 'dp-microphone',
//             placeholder: 'textarea_modal.incidents.input_placeholder',
//             validators: [Validators.required],
//             disabled: false
//         }
//     ],
//     buttons: [
//         {
//             id: 'yes',
//             cssClass: 'general',
//             text: 'textarea_modal.message.button_request_service',
//             action: true
//         },
//     ],
//     checkboxes: [
//         {
//             id: 'seven_squares',
//             label: 'textarea_modal.message.checkboxes.seven_squares',
//             defaultValue: false,
//             // validators: [Validators.requiredTrue]
//         },
//         {
//             id: 'defective',
//             label: 'textarea_modal.message.checkboxes.defective',
//             defaultValue: false,
//             // validators: [Validators.requiredTrue]
//         },
//         {
//             id: 'others',
//             label: 'textarea_modal.message.checkboxes.others',
//             defaultValue: false,
//             // validators: [Validators.requiredTrue]
//         }
//     ]
// }

export const textareaModalNewMessage: ModalTextareaConfig = {
    title: 'textarea_modal.new_message.title',
    inputs: [
        {
            id: 'textarea',
            defaultValue: '',
            type: 'text',
            // icon: 'dp-microphone',
            placeholder: 'textarea_modal.new_message.input_placeholder',
            validators: [Validators.required],
            disabled: false
        }
    ],
    buttons: [
        {
            id: 'send',
            cssClass: 'general',
            text: 'textarea_modal.new_message.button_send',
            action: true,
            validate: true,
        },
        {
            id: 'template',
            cssClass: 'secondary',
            text: 'textarea_modal.new_message.button_template',
            modal: true,
            validate: false,
        },
    ],
    checkboxes: [
        {
            id: 'seven_squares',
            label: 'textarea_modal.new_message.checkboxes.send_to_all',
            defaultValue: false,
            // validators: [Validators.requiredTrue]
        },
    ]
}

// export const wheelModalConfig: WheelModalData[] = [
//     {
//         id: '1',
//         text: 'AL45',
//         time: new Date()
//     },
//     {
//         id: '2',
//         text: 'AL12',
//         time: new Date()
//     },
//     {
//         id: '3',
//         text: 'AL25',
//         time: new Date()
//     },
//     {
//         id: '4',
//         text: 'AL61',
//         time: new Date()
//     },
//     {
//         id: '5',
//         text: 'AL61',
//         time: new Date()
//     },
//     {
//         id: '6',
//         text: 'AL61',
//         time: new Date()
//     },
//     {
//         id: '7',
//         text: 'AL61',
//         time: new Date()
//     },
//     {
//         id: '8',
//         text: 'AL61',
//         time: new Date()
//     },
//     {
//         id: '9',
//         text: 'AL61',
//         time: new Date()
//     },
//     {
//         id: '9',
//         text: 'AL61',
//         time: new Date()
//     },
//     {
//         id: '9',
//         text: 'AL61',
//         time: new Date()
//     },
//     {
//         id: '9',
//         text: 'AL61',
//         time: new Date()
//     },
//     {
//         id: '9',
//         text: 'AL61',
//         time: new Date()
//     },
//     {
//         id: '9',
//         text: 'AL61',
//         time: new Date()
//     },
// ]

// export const carModal: CarModalConfig = {
//     title: 'car_modal.title',
//     subtitle: 'car_modal.subtitle',
//     icon: 'dp-car-example',
//     progress: 50,
// }