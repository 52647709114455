import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { RadioModal } from '@components/radio-modal/radio-modal.component';
import { environment } from '@environments/environment';
import { Configuration } from '@interfaces/configuration';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { Countries_EN, Countries_ES } from '@static_data/countries';
import parsePhoneNumberFromString, { getCountries, getCountryCallingCode, isValidPhoneNumber } from 'libphonenumber-js';
import { ModalService } from './modal.service';
import { RestService } from './rest.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@interfaces/user';
import { actionModalPhoneOption } from '@static_data/custom';
import { DpUtilsService } from 'digitup-lib/dist/digitup-lib';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';

interface CountryType {
  countryCode: string;
  countryName: string;
  countryCodeText: string;
}

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  configurations?: Configuration;

  constructor(
    private platform: Platform,
    private router: Router,
    private modalController: ModalController,
    private nav: NavController,
    private rest: RestService,
    private modal: ModalService,
    private translate: TranslateService,
    private utils: DpUtilsService
  ) { }

  async configBackButtonAndroid() {
    const device = await Device.getInfo();
    if (device.platform === 'android') {
      this.platform.backButton.subscribeWithPriority(10, () => {
        this.handleBackButton();
      });
    }
  }

  async handleBackButton() {
    if (this.router.url !== '/tabs/home' && this.router.url !== '/login') {
      const mod = await this.modalController.getTop();
      if (mod) {
        await this.modalController.dismiss();
      } else {
        this.nav.back();
      }
    } else {
      App.exitApp();
    }
  }

  async getConfiguration(force = false) {
    if (!this.configurations || force) {
      const resp = await this.rest.getConfigurations(environment.configurationId);
      if (resp?.ok && resp?.response) {
        this.configurations = resp.response;
      }
    }
    return this.configurations;
  }

  async openStore() {
    const dev = await Device.getInfo();
    if (dev.platform === 'ios') {
      window.open('https://apps.apple.com/es/app/mallorcab-client/id6470745765', '_system');
    } else {
      window.open('https://play.google.com/store/apps/details?id=com.mallorcab.client', '_system');
    }
  }

  async openStoreBrowser() {
    const userAgent = navigator.userAgent;
    const isIOS = /iPad|iPhone|iPod/.test(userAgent);

    if (isIOS) {
      window.open('https://apps.apple.com/es/app/mallorcab-client/id6470745765', '_system');
    } else {
      window.open('https://play.google.com/store/apps/details?id=com.mallorcab.client', '_system');
    }
  }

  getCountryList(language = 'es'): CountryType[] {
    const countries = getCountries();
    const countriesNames = language === 'es' ? Countries_ES : Countries_EN;
    const priorityCountries = ['ES', 'PT', 'DE', 'GB', 'FR', 'CH', 'IT', 'DK']; // Códigos de los países prioritarios

    // Construir el array de objetos
    const countryList = countries.map(countryCode => {
      const callingCode = getCountryCallingCode(countryCode);
      const countryName = countriesNames.find(elem => elem.code === countryCode)?.name || '';
      return {
        countryCode: `+${callingCode}`,
        countryName: `${countryName || countryCode} (+${callingCode})`,
        countryCodeText: countryCode
      };
    });
    countryList.sort((a, b) => {
      const aPriority = priorityCountries.indexOf(a.countryCodeText);
      const bPriority = priorityCountries.indexOf(b.countryCodeText);
      if (aPriority !== -1 && bPriority !== -1) {
        return aPriority - bPriority;
      }
      if (aPriority !== -1) {
        return -1;
      }
      if (bPriority !== -1) {
        return 1;
      }
      return a.countryCodeText.localeCompare(b.countryCodeText);
    });
    return countryList;
  }

  getPhoneParser(phone: string) {
    const phoneNumber = parsePhoneNumberFromString(phone);

    if (phoneNumber) {
      const countryCode = phoneNumber.countryCallingCode;

      const nationalNumber = phoneNumber.nationalNumber;
      return { prefix: `+${countryCode}`, phone: nationalNumber };
    } else {
      return null
    }
  }

  async selectCountryCode(language: string, selectId?: string) {
    // const selectId = this.userForm.get('prefix')?.value;
    const countryList = this.getCountryList(language);
    const config: RadioModal = {
      title: this.translate.instant('action_modal.phone_option.country_list'),
      selectActiveType: 'bold',
      data: [],
      autoDismiss: true,
      selectId,
      type: 'phone',
      inputs: [
        {
          id: 'search',
          defaultValue: '',
          type: 'text',
          leftIcon: 'dp-search',
          placeholder: this.translate.instant('action_modal.phone_option.search_country'),
          disabled: false
        }
      ]
    }
    config.data = [];
    countryList.forEach(elem => {
      config.data.push({
        text: elem.countryName,
        id: elem.countryCode,
        obj: elem
      });
    });

    const result = await this.modal.openRadioModal('radio_modal', config, true);
    console.log('result', result);
    if (result?.select?.id) {
      return result.select.id;
    } else {
      return null;
    }
  }

  getCountryCodeName(prefix: string) {
    const countryList = this.getCountryList();
    return countryList ? countryList.find(elem => elem.countryCode === prefix)?.countryCodeText : '';
  }

  isValidPhone(phone: string) {
    return isValidPhoneNumber(phone);
  }

  async checkPhone() {
    const currentUser = await this.rest.getCurrentUser()
    if (currentUser && !currentUser.phone) {
      actionModalPhoneOption.user = currentUser;
      const result = await this.modal.openActionModal(actionModalPhoneOption, false);
      console.log('result', result);
      if (result?.phone) {
        const resultSave = await this.rest.editSingleUser(currentUser.id, { phone: result.phone });
        if (resultSave.ok) {
          await this.rest.getCurrentUser(true);
          await this.utils.showToast({ message: this.translate.instant('general.save_success') });
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  openSettings() {
    NativeSettings.open({
      optionAndroid: AndroidSettings.Location,
      optionIOS: IOSSettings.LocationServices
    });
  }

  calculateTimeDifference(start: string, end: string): number {
    // Parsear las fechas ISO 8601
    const startDate = new Date(start);
    const endDate = new Date(end);

    // Calcular la diferencia en milisegundos
    const diffInMilliseconds = endDate.getTime() - startDate.getTime();

    // Convertir milisegundos en minutos y retornar el resultado
    return Math.round(diffInMilliseconds / (1000 * 60));
  }
}
