import { CommonModule } from '@angular/common';
import { Component, EnvironmentInjector, NgZone, inject } from '@angular/core';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { StatusBar, Style } from '@capacitor/status-bar';
import { environment } from '@environments/environment';
import { User } from '@interfaces/user';
import { IonicModule } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from '@services/analytics.service';
import { AuthService } from '@services/auth.service';
import { JourneyWatchService } from '@services/journey-watch.service';
import { ModalService } from '@services/modal.service';
import { PushService } from '@services/push.service';
import { RestService } from '@services/rest.service';
import { SocketService } from '@services/socket.service';
import { StorageService } from '@services/storage.service';
import { UtilsService } from '@services/utils.service';
import { actionModalDownloadApp } from '@static_data/custom';
import { DpTranslateEngineService, DpUtilsService } from 'digitup-lib/dist/digitup-lib';
import { register } from 'swiper/element/bundle';
register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule],
})
export class AppComponent {
  currentNetworkStatus = true;
  environmentInjector = inject(EnvironmentInjector);
  currentLanguage!: string;

  constructor(
    private translateEngine: DpTranslateEngineService,
    private storage: StorageService,
    private analytics: AnalyticsService,
    private zone: NgZone,
    private router: Router,
    private utils: DpUtilsService,
    private translate: TranslateService,
    private push: PushService,
    private socketService: SocketService,
    private localUtils: UtilsService,
    private journeyService: JourneyWatchService,
    private modal: ModalService,
    private rest: RestService,
    private authService: AuthService
  ) {
    this.initApp();
  }

  async initApp() {
    await this.setLanguage();
    this.analytics.start(true);
    this.initDeepLinks();
    setTimeout(() => {
      this.enableNetwork();
    }, 1500);
    console.log(this.currentLanguage);
    setTimeout(() => {
      console.log('currentSocketId Client', this.socketService.currentSocketId);
    }, 3000);

    if (Capacitor.isPluginAvailable('PushNotifications')) {
      this.push.enablePush();
    }
    // await this.getCurrentUser();
    this.localUtils.configBackButtonAndroid();
    if (Capacitor.getPlatform() === 'ios') {
      StatusBar.setStyle({ style: Style.Light });
      StatusBar.setOverlaysWebView({ overlay: true });
    }
    this.checkCurrentJourney();
    this.showDownloadAppMessage();
  }

  checkCurrentJourney() {
    App.addListener('appStateChange', async (state) => {
      const user = await this.getCurrentUser();
      if (user && state.isActive) {
        await this.journeyService.checkJourney();
      }
    });
  }

  async showDownloadAppMessage() {
    setTimeout(async () => {
      const currentFbUser = await this.authService.getCurrentUser();
      let user;
      if (currentFbUser) {
        user = await this.rest.getCurrentUser();
      }
      const isMobile = window.innerWidth < 800;
      if (!Capacitor.isNativePlatform() && isMobile && (!user || !user?.role.includes('client-hotel'))) {
        const result = await this.modal.openActionModal(actionModalDownloadApp, true);
        if (result?.button?.id === 'yes') {
          await this.localUtils.openStoreBrowser();
        }
      }
    }, 3000);
  }

  async getCurrentUser() {
    const currentFbUser = await this.authService.getCurrentUser();
    let user;
    if (currentFbUser) {
      user = await this.rest.getCurrentUser();
    }
    return user;
  }

  // async getCurrentUser() {
  //   await this.auth.onAuthStateChanged(async user => {
  //     console.log('user', user);
  //     if (user) {
  //       await this.rest.getCurrentUser(true);
  //       this.localUtils.getConfiguration(true);
  //     }
  //   });
  // }

  initDeepLinks() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const slug = event.url.split(environment.frontUrl).pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        } else {
          this.router.navigateByUrl('/');
        }
      });
    });
  }

  async enableNetwork() {
    console.log('enable network');
    const statusStart = await Network.getStatus();
    console.log('enable network', statusStart);
    if (statusStart && !statusStart.connected) {
      await this.utils.showToast({ message: this.translate.instant('errorMessages.network_error'), cssClass: 'warning' });
    }
    Network.addListener('networkStatusChange', async (status) => {
      console.log('Network status changed', status);
      if (status && !status.connected && this.currentNetworkStatus) {
        this.currentNetworkStatus = false;
        await this.utils.showToast({ message: this.translate.instant('errorMessages.network_error'), cssClass: 'warning' });
      } else if (status && status.connected && !this.currentNetworkStatus) {
        this.currentNetworkStatus = true;
      }
    });
  }

  async setLanguage() {
    const platformLang = window.navigator.language.split('-')[0];
    if (environment.language.available.includes(platformLang)) {
      await this.translateEngine.setAppLang(environment.language.available, platformLang);
    } else {
      await this.translateEngine.setAppLang(environment.language.available, environment.language.defaultLang);
    }
    const user: User = await this.storage.getItem('taxi_app_current_user')

    if (user?.language) {
      await this.translateEngine.changeLanguage(user.language);
    }
  }
}
