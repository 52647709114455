import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { StorageService } from '@services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class OnboardingGuard {
  constructor(
    private storage: StorageService,
    private nav: NavController
  ) { }

  async canActivateFn(): Promise<boolean> {
    return new Promise(async resolve => {
      const firtInit = await this.storage.getItem('firstInit');
      if (firtInit) {
        resolve(true);
      } else {
        resolve(false);
        this.nav.navigateRoot('onboarding');
      }
    });
  }
}
