import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';

export type RadioModalType = 'phone' | 'cancel' | 'other';

export interface RadioModalData {
  id: string;
  text: string;
  template?: string;
  obj?: any;
}

export interface InputType {
  id: string;
  type: string;
  defaultValue: string;
  placeholder: string;
  leftIcon: string;
  rightIcon?: string;
  validators?: any[];
  disabled: boolean;
}

export interface RadioModal {
  title: string;
  selectActiveType: 'check' | 'bold';
  selectId?: string;
  data: RadioModalData[];
  inputs?: InputType[];
  autoDismiss?: boolean;
  type: RadioModalType;
}

@Component({
  selector: 'app-radio-modal',
  templateUrl: './radio-modal.component.html',
  styleUrls: ['./radio-modal.component.scss'],
})
export class RadioModalComponent implements OnInit {

  @Input() config!: RadioModal;
  otherReason = '';

  constructor(
    private modalController: ModalController,
  ) { }

  selectedId = '';
  selectedIdText = '';
  filteredData: RadioModalData[] = []
  form!: FormGroup;

  ngOnInit() {
    this.filteredData = this.config.data;
    if (this.config.inputs && this.config.inputs.length) {
      const formControlsConfig: any = {};
      this.config.inputs.forEach(field => {
        if (field.validators) {
          formControlsConfig[field.id] = new FormControl({ value: field.defaultValue, disabled: field.disabled }, field.validators);
        }
        else {
          formControlsConfig[field.id] = new FormControl({ value: field.defaultValue, disabled: field.disabled }, []);
        }
      })
      this.form = new FormGroup(formControlsConfig);
      this.config.inputs.forEach(field => {
        this.getInputValue(field.id)
      })
    }

    if (this.config.data && this.config.selectId) {
      this.selectedId = this.config.selectId;
    }
  }

  close() {
    this.modalController.dismiss(null)
  }

  setActive(id: string) {
    this.selectedId = id;
    if (this.config.autoDismiss) {
      this.getData();
    }
  }

  getData() {
    const data = this.filteredData.filter(elem => elem.id === this.selectedId);
    if (this.otherReason && data[0].obj) {
      data[0].obj.reasonNote = this.otherReason;
    }
    this.modalController.dismiss({ select: data[0] });
  }

  getInputValue(inputId: string) {
    if (inputId === 'search') {
      this.form.get(inputId)?.valueChanges.subscribe(value => {
        if (!value) {
          this.filteredData = this.config.data;
        } else {
          this.filteredData = this.config.data.filter(item =>
            item.text.toLowerCase().includes(value.toLowerCase())
          );
        }
      })
    }
  }

}
