<ion-header class="modal-header">
  <ion-toolbar>
    <div class="title-container">
      <div class="title"> {{ config.title | translate }} </div>
      <div class="close" (click)="close()">
        <ion-icon name="dp-close"></ion-icon>
      </div>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="main_box">
    <ng-container *ngIf="config.inputs && config.inputs.length">
      <form [formGroup]="form">
        <div class="input-container">
          <ng-container *ngFor="let input of config.inputs">
            <ion-item class="input general" lines="none">
              <ng-container *ngIf="input.leftIcon">
                <ion-icon [name]="input.leftIcon"></ion-icon>
              </ng-container>
              <ion-input [type]="input.type" [formControlName]="input.id"
                [attr.disabled]="input.disabled ? input.disabled : null"
                [placeholder]="input.placeholder | translate"></ion-input>

              <ng-container *ngIf="input.rightIcon">
                <ion-icon class="right left_icon" [name]="input.rightIcon"></ion-icon>
              </ng-container>
            </ion-item>
          </ng-container>
        </div>
      </form>
    </ng-container>
    <div class="container">
      <div class="data-container">
        <ng-container *ngIf="filteredData && filteredData.length; else elseTemplate">
          <ng-container *ngFor="let row of filteredData">
            <div class="row" (click)="setActive(row.id)">
              <div class="text" [class.active]="row.id === selectedId"> {{ row.text }} </div>
              <ng-container *ngIf="row.id === selectedId && config.selectActiveType === 'check'">
                <div class="icon">
                  <ion-icon name="dp-check"></ion-icon>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
        <div class="other_reason" *ngIf="config.type === 'cancel' && selectedId === 'other'">
          <ion-textarea [(ngModel)]="otherReason" class="general height_auto" [rows]="3"
            [placeholder]="'action_modal.cancel_journey.placeholder' | translate"></ion-textarea>
        </div>
        <ng-template #elseTemplate>
          <div class="empty-container">
            <div class="text">
              {{ 'radio_modal.empty' | translate }}
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</ion-content>
<ion-footer class="general-footer" *ngIf="!config.autoDismiss">
  <ion-toolbar>
    <div class="content" *ngIf="config.type === 'cancel'; else normalCase">
      <ion-button class="general cancel medium" fill="clear" (click)="getData()" [disabled]="selectedId === ''">
        <ng-container>
          {{'action_modal.cancel_journey.buttons.confirm' | translate}}
        </ng-container>
      </ion-button>
      <ion-button class="general medium" fill="clear" (click)="close()">
        <ng-container>
          {{'action_modal.cancel_journey.buttons.cancel' | translate}}
        </ng-container>
      </ion-button>
    </div>
    <ng-template #normalCase>
      <div class="content">
        <ion-button class="general medium" fill="clear" (click)="getData()" [disabled]="selectedId === ''">
          <ng-container>
            {{'radio_modal.buttom_confirm' | translate}}
          </ng-container>
        </ion-button>
      </div>
    </ng-template>
  </ion-toolbar>
</ion-footer>