import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ModalService } from '@services/modal.service';
import { Orientation, ScreenOrientationService } from '@services/screen-orientation.service';
import { StorageService } from '@services/storage.service';
import { radioModalEnabledTemplate } from '@static_data/custom';

export interface GetDataEvent {
  button?: {
    id: string
  },
  input?: {
    [key: string]: string
  }
}

export interface ButtonType {
  id: string;
  text: string;
  cssClass: string;
  icon?: string;
  action?: boolean;
  validate?: boolean;
  modal?: boolean;
}

export interface InputType {
  id: string;
  type: string;
  defaultValue: string;
  placeholder: string;
  icon?: string;
  validators?: any[];
  disabled: boolean;
}

export interface CheckboxType {
  id: string;
  label: string;
  defaultValue: boolean;
  validators?: any;
}

export interface ModalTextareaConfig {
  title?: string;
  buttons?: ButtonType[];
  inputs?: InputType[];
  checkboxes?: CheckboxType[]
}

@Component({
  selector: 'app-textarea-modal',
  templateUrl: './textarea-modal.component.html',
  styleUrls: ['./textarea-modal.component.scss'],
})
export class TextareaModalComponent implements OnInit {

  config!: ModalTextareaConfig;
  form!: FormGroup;

  orientation!: Orientation;
  darkMode!: boolean;

  constructor(
    private modalController: ModalController,
    private modalService: ModalService,
    private screenOrientationService: ScreenOrientationService,
    private storageService: StorageService,
  ) {
    this.screenOrientationService.getScreenOrientation().subscribe(orientation => {
      this.orientation = orientation;
    })
    this.storageService.getItemObservable('dark_mode').subscribe(res => {
      this.darkMode = res;
    });
  }

  ngOnInit() {
    const formControlsConfig: any = {};
    if (this.config.inputs && this.config.inputs.length) {
      this.config.inputs.forEach(field => {
        if (field.validators) {
          formControlsConfig[field.id] = new FormControl({ value: field.defaultValue, disabled: field.disabled }, field.validators);
        }
        else {
          formControlsConfig[field.id] = new FormControl({ value: field.defaultValue, disabled: field.disabled }, []);
        }
      })
    }
    if (this.config.checkboxes && this.config.checkboxes.length) {
      this.config.checkboxes.forEach(field => {
        if (field.validators) {
          formControlsConfig[field.id] = new FormControl(field.defaultValue, field.validators);
        }
        else {
          formControlsConfig[field.id] = new FormControl(field.defaultValue, []);
        }
      })
    }
    if (this.config.inputs && this.config.inputs.length || this.config.checkboxes && this.config.checkboxes.length) {
      this.form = new FormGroup(formControlsConfig);
    }
  }

  close() {
    this.modalController.dismiss(null)
  }

  getInputEvent(inputId: string) {
    if (inputId === 'text') {
      console.log('copy');

    }
  }

  getData(button: ButtonType) {
    if (this.config.inputs && this.config.inputs.length || this.config.checkboxes && this.config.checkboxes.length) {
      let data: GetDataEvent = {
        button: {
          id: button.id
        },
        input: {}
      }
      if (this.config.inputs && this.config.inputs.length) {
        this.config.inputs.forEach(field => {
          if (data.input) [
            data.input[field.id] = this.form.value[field.id]
          ]
        })
      }
      if (this.config.checkboxes && this.config.checkboxes.length) {
        this.config.checkboxes.forEach(field => {
          if (data.input) [
            data.input[field.id] = this.form.value[field.id]
          ]
        })
      }
      this.modalController.dismiss(data)
    }
    else {
      let data: GetDataEvent = {
        button: {
          id: button.id
        }
      }
      this.modalController.dismiss(data)
    }
  }

  async openModal() {
    const result = await this.modalService.openRadioModal('radio_modal', radioModalEnabledTemplate);
    if (result !== null) {
      const value = this.form.get('textarea')?.value;
      const template = result.select.template;
      if (value !== '') {
        const newTemplate = template.replace(/\[.*?\]/g, value);
        this.form.get('textarea')?.setValue(newTemplate);
      }
      else {
        this.form.get('textarea')?.setValue(template)
      }
    }
  }
}
