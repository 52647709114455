import { Injectable } from '@angular/core';
import { FCM } from '@capacitor-community/fcm';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { PushNotifications } from '@capacitor/push-notifications';
import { NavController } from '@ionic/angular';
import { DpStorageService } from 'digitup-lib/dist/digitup-lib';
import { RestService } from './rest.service';
import { ModalService } from './modal.service';
import { JourneyWatchService } from './journey-watch.service';

@Injectable({
  providedIn: 'root'
})
export class PushService {
  tokenRegister: any;
  isIos = false;
  enabled = false;

  constructor(
    private storage: DpStorageService,
    private nav: NavController,
    private rest: RestService,
    private modal: ModalService,
    private journeyService: JourneyWatchService
  ) { }

  async enablePush() {
    if (this.enabled) {
      return;
    }
    this.enabled = true;
    await this.addListners();

    const device = await Device.getInfo();
    this.isIos = device.platform === 'ios';

    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }

    await PushNotifications.register();
  }

  async addListners() {
    await PushNotifications.addListener('registration', token => {
      console.log('Registration token: ', token.value);
      this.tokenRegister = token.value;
      // this.checkFcmToken();
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: any) => {
        await this.checkNotification(notification);
      }
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (notification) => {
        await this.checkNotification(notification);
      }
    );
  }

  async checkNotification(notification: any) {
    // this.analytics.sendEventByType('notification_recieve');
    console.log('Push received: ' + JSON.stringify(notification));
    // here recive notification in app
    const title = notification.title;
    const subtitle = notification.body;

    if (notification?.data) {
      const data = notification.data;
      const config = {
        title,
        subtitle,
        buttons: [
          {
            id: 'close',
            cssClass: 'secondary',
            text: 'general.close'
          }
        ]
      }
      if (data.type === 'NEW_MESSAGE') {
        config.buttons.unshift({
          id: 'open',
          cssClass: 'general',
          text: 'general.open'
        });
        const result = await this.modal.openActionModal(config, true);
        if (result?.button?.id === 'open') {
          if (data.journeyId) {
            await this.nav.navigateForward(`/chat-detail/${data.journeyId}`);
          } else {
            const currentJourneyId = this.journeyService.getCurrentJourneyId();
            if (currentJourneyId) {
              await this.nav.navigateForward(`/chat-detail/${currentJourneyId}`);
            }
          }
        }
      } else if (data.type === 'RATE_TRIP') {
        if (data.journeyId) {
          await this.nav.navigateForward(`/travel-details/${data.journeyId}`);
        } else {
          await this.nav.navigateForward('/activity');
        }
      }
    }

  }

  async getToken() {
    try {
      if (Capacitor.isPluginAvailable('PushNotifications')) {
        await this.enablePush();
        const tokenFromFCM = await FCM.getToken();
        return tokenFromFCM?.token || this.tokenRegister;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error get Token', error);

    }
  }

  async checkFcmToken() {
    const oldFcmToken = await this.storage.getItem('taxt_client_fcm_token');
    const fcmToken = await this.getToken();
    console.log('checkFcmToken fcmToken', fcmToken);
    console.log('checkFcmToken oldFcmToken', oldFcmToken);
    if (fcmToken) {
      if (fcmToken && (!oldFcmToken || (oldFcmToken !== fcmToken))) {
        const data: any = { fcmToken };
        if (oldFcmToken) {
          data.oldFcmToken = oldFcmToken;
        }
        const updateResult = await this.rest.updateToken(data);
        if (updateResult) {
          await this.storage.setItem('taxt_client_fcm_token', fcmToken);
        }
      }
    }
  }
}
