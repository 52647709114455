import { Injectable } from '@angular/core';
import { ActionModalComponent, ModalActionConfig } from '@components/action-modal/action-modal.component';
import { PreparingTripComponent } from '@components/preparing-trip/preparing-trip.component';
import { RadioModal, RadioModalComponent } from '@components/radio-modal/radio-modal.component';
import { ModalTextareaConfig, TextareaModalComponent } from '@components/textarea-modal/textarea-modal.component';
import { TripParams } from '@interfaces/trip-params';
import { ModalController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})

export class ModalService {
  preparingTripModal?: HTMLIonModalElement;

  constructor(
    private modalController: ModalController
  ) { }

  async openActionModal(config: ModalActionConfig, backdropDismiss = false, cssClass = 'action_modal') {
    const modal = await this.modalController.create({
      component: ActionModalComponent,
      cssClass,
      backdropDismiss,
      componentProps: { config }
    });

    await modal.present();

    const resultModal = await modal.onWillDismiss();
    return resultModal.data;
  }

  async openPreparingTrip(tripParams: TripParams) {
    if (this.preparingTripModal) {
      return;
    }
    this.preparingTripModal = await this.modalController.create({
      component: PreparingTripComponent,
      componentProps: { tripParams },
      cssClass: 'preparing_trip',
    });

    await this.preparingTripModal.present();

    const resultModal = await this.preparingTripModal.onWillDismiss()
    this.preparingTripModal = undefined;
    return resultModal;
  }


  // async openChatDetailModal(cssClass: string) {
  //   const modal = await this.modalController.create({
  //     component: ChatDetailPage,
  //     cssClass: cssClass,
  //     backdropDismiss: false,
  //     componentProps: {
  //     }
  //   });

  //   await modal.present()
  // }

  async openTextareaModal(cssClass: string, config: ModalTextareaConfig, backdropDismiss = true) {
    const modal = await this.modalController.create({
      component: TextareaModalComponent,
      cssClass: cssClass,
      backdropDismiss,
      componentProps: { config }
    });

    await modal.present();

    const resultModal = await modal.onWillDismiss();
    return resultModal.data;
  }

  async openRadioModal(cssClass: string, config: RadioModal, backdropDismiss = true) {
    const modal = await this.modalController.create({
      component: RadioModalComponent,
      cssClass: cssClass,
      backdropDismiss,
      componentProps: { config }
    });

    await modal.present();

    const resultModal = await modal.onWillDismiss();
    return resultModal.data;
  }


}