import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { BehaviorSubject, Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private storageSubject = new BehaviorSubject<any>({});

  constructor() {
    this.init()
  }

  async init() {
    try {
      const result = await JSON.parse(JSON.stringify(await Preferences.keys()));
      if (result.keys.length) {
        const storageData: any = {};
        for (const element of result.keys) {
          const key = element;
          const value = await this.getItem(key);
          storageData[key] = value;
        }
        this.storageSubject.next(storageData);
      } else {
        this.storageSubject.next({});
      }
    } catch (error) {
      // console.error("Ошибка при инициализации:", error);
    }
  }

  async setItem(key: string, value: any) {
    await Preferences.set({ key, value: JSON.stringify(value) });
  }

  async getItem(key: string) {
    const elem: any = await Preferences.get({ key });
    return JSON.parse(elem?.value || null);
  }

  async deleteItem(key: string) {
    await Preferences.remove({ key });
    return true;
  }

  async deleteAll() {
    return await Preferences.clear();
  }

  // Метод для добавления данных в локальное хранилище и обновления Observable
  setItemObservable(key: string, value: any): void {
    const storageData = { ...this.storageSubject.value, [key]: value };
    this.setItem(key, value)
    this.storageSubject.next(storageData);
  }

  // Метод для получения данных из локального хранилища
  getItemObservable(key: string): Observable<any> {
    return this.storageSubject.pipe(
      map(storageData => storageData[key])
    );
  }

  // Метод для удаления данных из локального хранилища и обновления Observable
  deleteItemObservable(key: string): void {
    const storageData = { ...this.storageSubject.value };
    delete storageData[key];
    this.deleteItem(key)
    this.storageSubject.next(storageData);
  }

  // Метод для очистки локального хранилища и обновления Observable
  deleteAllObservable(): void {
    // localStorage.removeItem('myStorage');
    this.storageSubject.next({});
  }
}
