import { Injectable } from '@angular/core';
import { Journey } from '@interfaces/journey';
import { BehaviorSubject, Observable } from 'rxjs';
import { RestService } from './rest.service';


@Injectable({
  providedIn: 'root'
})
export class JourneyWatchService {

  private $currentJourney = new BehaviorSubject<Journey | null>(null);

  constructor(private rest: RestService) { }

  async initJourney() {
    const currentJourney = await this.rest.getCurrentJourney()
    if (currentJourney.ok) {
      this.$currentJourney.next(currentJourney.response);
    }
  }

  async checkJourney() {
    await this.initJourney();
    const result = this.getCurrentJourney();
    if (!result) {
      this.destroyJourney();
    }
  }

  async destroyJourney() {
    console.log('destroy journey');
    this.$currentJourney.next(null);
  }

  public nextJourney(journey: Journey) {
    this.$currentJourney.next(journey);
  }

  getCurrentJourney() {
    return this.$currentJourney.getValue();
  }

  getCurrentJourneyId() {
    return this.$currentJourney.getValue()?.id;
  }

  getJourneyCurrentObserable(): Observable<Journey | null> {
    return this.$currentJourney.asObservable();
  }

}
