import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Configuration } from '@interfaces/configuration';
import { CreateIncidentDto, Incident } from '@interfaces/incident';
import { AddressDetail, CancelReason, CreateJourneyDto, FindJourneyDto, GetJourneyPriceDto, Journey } from '@interfaces/journey';
import { MessageMain, NewMessageDto } from '@interfaces/message';
import { Notification } from '@interfaces/notification';
import { Province } from '@interfaces/province';
import * as ReqResp from '@interfaces/rest';
import { EditFcmToken, User } from '@interfaces/user';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DpRestService, DpStorageService, DpUtilsService } from 'digitup-lib/dist/digitup-lib';
import { BehaviorSubject } from 'rxjs';


interface GeneralResponse<Type> {
  ok: boolean;
  response: Type;
}

interface GeneralResponseData<Type> {
  data: {
    result: Type,
    totalElements?: number,
    totalPages?: number
  }
}

// interface GeneralResponseNoData<Type> {
//   result: Type,
//   totalElements?: number,
//   totalPages?: number
// }

@Injectable({
  providedIn: 'root'
})
export class RestService {
  updateUser$: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);

  constructor(
    private rest: DpRestService,
    private storage: DpStorageService,
    private nav: NavController,
    private translate: TranslateService,
    private utils: DpUtilsService
  ) { }

  ////////////////////////////////////////
  //////////// Users
  ////////////////////////////////////////

  // Register regular user
  async registerRegularUser(data: ReqResp.RegisterRegularUserRequest): Promise<ReqResp.RegisterRegularUserResponse> {
    console.log('registerRegularUser', data);
    return await this.rest.generalPost('/users/auth/signup', data, true);
  }

  // Register social user
  async existsUid(uid: string): Promise<GeneralResponse<any>> {
    return await this.rest.generalGet(`/users/exists/${uid}`);
  }

  // Register social user
  async registerSocialUser(data: ReqResp.RegisterSocialUserRequest): Promise<ReqResp.RegisterSocialUserResponse> {
    return await this.rest.generalPost('/users/auth/signup/social', data);
  }

  // Get all users
  async getAllUsers(): Promise<ReqResp.GetAllUsersResponse> {
    return await this.rest.generalGet('/users');
  }

  // Get single user by uid
  async getSingleUserByUid(uid: string): Promise<ReqResp.GetSingleUserByUidResponse> {
    return await this.rest.generalGet(`/users/by-uid/${uid}`);
  }

  // Delete user
  async deleteUser(data: ReqResp.DeleteUserRequest): Promise<ReqResp.DeleteUserResponse> {
    return await this.rest.generalDelete('/users', data);
  }

  // // Get current user
  // async getCurrentUser(data: ReqResp.GetCurrentUserRequest = {}): Promise<ReqResp.GetCurrentUserResponse> {
  //   return await this.rest.generalGet('/users/currentUser', data);
  // }
  async getCurrentUser(force = false): Promise<User | null> {
    const user = await this.storage.getItem('taxi_app_current_user');
    if (user && !force) {
      return user;
    } else {
      const apiUser = await this.rest.generalGet('/users/currentUser');
      if (apiUser.ok && apiUser.response) {
        if (apiUser.response.role?.includes('client')) {
          await this.storage.setItem('taxi_app_current_user', apiUser.response);
          const user: User = apiUser.response;
          this.updateUser$.next(user);
          return user;
        } else {
          await this.utils.showToast({ message: this.translate.instant('auth.login.user_role_error'), cssClass: 'danger' });
          this.nav.navigateRoot('/login');
          return null;
        }
      } else {
        return null;
      }
    }
  }

  // Validate credit card
  async validateCard(paymentMethodId: string, last4: string) {
    return await this.rest.generalPatch('/users/validate-card', {}, { paymentMethodId, last4 });
  }

  // Confirm card
  async confirmCard() {
    return await this.rest.generalPost('/users/confirm-card');
  }

  // Delete card
  async deleteCard() {
    return await this.rest.generalDelete('/users/delete-card');
  }

  // Get recovery password link
  async getRecoveryPasswordLink(email: string): Promise<ReqResp.GetRecoveryPasswordLinkResponse> {
    return await this.rest.generalGet(`/users/recovery-link/${email}`);
  }

  // Get single user by id
  async getSingleUserById(id: string): Promise<ReqResp.GetSingleUserByIdResponse> {
    return await this.rest.generalGet(`/users/${id}`);
  }

  // Edit single user
  async editSingleUser(id: number, data: ReqResp.EditSingleUserRequest): Promise<ReqResp.EditSingleUserResponse> {
    return await this.rest.generalPatch(`/users/edit`, data, { id });
  }

  // Send confirm email for user
  async sendConfirmEmailForUser(data: ReqResp.SendConfirmEmailForUserRequest): Promise<ReqResp.SendConfirmEmailForUserResponse> {
    return await this.rest.generalPost('/users/confirm-link', data);
  }

  // Recovery password
  async recoveryPassword(): Promise<ReqResp.RecoveryPasswordResponse> {
    return await this.rest.generalPost('/users/auth/forgot-password');
  }

  // Resend confirm email
  async resendConfirmEmail(id: string): Promise<ReqResp.ResendConfirmEmailResponse> {
    return await this.rest.generalPost(`/users/${id}/send-email-verification`);
  }

  // Restore user
  async restoreUser(id: string): Promise<ReqResp.RestoreUserResponse> {
    return await this.rest.generalPatch(`/users/${id}/restore`);
  }

  // disable user
  async disableUser(id: string): Promise<ReqResp.DisableUserResponse> {
    return await this.rest.generalPatch(`/users/${id}/disable`);
  }

  // enable user
  async enableUser(id: string): Promise<ReqResp.EnableUserResponse> {
    return await this.rest.generalPatch(`/users/${id}/enable`);
  }

  // Set role manager for regular user
  async setRoleManagerForRegularUser(id: string): Promise<ReqResp.SetRoleManagerForRegularUserResponse> {
    return await this.rest.generalPatch(`/users/set-manager/${id}`);
  }

  async updateToken(data: EditFcmToken) {
    return await this.rest.generalPatch('/users/update-fcm-token', {}, data);
  }

  ////////////////////////////////////////
  //////////// Licenses
  ////////////////////////////////////////

  // Get license by ID
  async getLicenseById(id: string): Promise<ReqResp.GetLicenseByIdResponse> {
    return await this.rest.generalGet(`/licenses/${id}`);
  }

  ////////////////////////////////////////
  //////////// Journey
  ////////////////////////////////////////

  // Create new journey
  async createNewJourney(data: CreateJourneyDto): Promise<GeneralResponse<Journey>> {
    return await this.rest.generalPost('/journey', data);
  }

  // Get all journeys
  // async getAllJourneys(): Promise<ReqResp.GetAllJourneysResponse> {
  //   return await this.rest.generalGet('/journey');
  // }

  // Edit journey
  async editJourney(id: number, data: ReqResp.EditJourneyRequest): Promise<ReqResp.EditJourneyResponse> {
    return await this.rest.generalPut(`/journey/${id}`, data);
  }

  // Edit journey
  async setJourneyReview(id: number, data: ReqResp.SetReviewJourneyRequest): Promise<ReqResp.EditJourneyResponse> {
    return await this.rest.generalPut(`/journey/${id}`, data);
  }

  // Cancel journey
  async cancelJourney(journeyId: number, reason: CancelReason): Promise<GeneralResponse<any>> {
    const data = { journeyId, ...reason }
    return await this.rest.generalPut(`/journey/client/cancel-journey`, {}, data);
  }

  async getCurrentJourney(): Promise<GeneralResponse<Journey>> {
    const params = { type: 'USER' }
    return await this.rest.generalGet('/journey/currentJourney', params);
  }
  // Get journey by ID
  async getJourneyById(id: number): Promise<GeneralResponse<Journey>> {
    return await this.rest.generalGet(`/journey/${id}`);
  }

  // Get journeys by user ID
  // async getJourneysByUserId(reserve = false): Promise<GeneralResponse<GeneralResponseNoData<Journey[]>>> {
  //   const params: any = {
  //     'sort_by': 'id:desc',
  //     limit: 20
  //   };

  //   if (reserve) {
  //     params['reserve'] = true;
  //     params['status'] = 'RESERVE_WAITING';
  //     // params['or.status'] = 'RESERVE_WAITING,NEW,DRIVER_SEARCHING';
  //     // params['or'] = [{ 'status': 'RESERVE_WAITING' }, { 'status': 'NEW' }, { 'status': 'DRIVER_SEARCHING' }];
  //   }
  //   return await this.rest.generalGet(`/journey/for-user`, params);
  // }

  // Get journeys by user ID
  async getJourneysByUserIdV2(params: any): Promise<GeneralResponse<GeneralResponseData<Journey[]>>> {
    // const params: any = {
    //   'sort_by': 'id:desc',
    //   limit: 20,
    //   'where.status': status
    // };

    // if (reserve) {
    //   params['where.reserve'] = reserve;
    // }

    if (!params['sort_by']) {
      params['sort_by'] = 'id:desc';
    }

    return await this.rest.generalGet(`/journey/for-user-v2`, params);
  }

  // Get journeys by driver ID
  async getJourneysByDriverId(driverId: string): Promise<ReqResp.GetJourneysByDriverIdResponse> {
    return await this.rest.generalGet(`/journey/driver/${driverId}`);
  }

  // Get journeys by license ID
  async getJourneysByLicenseId(licenseId: string): Promise<ReqResp.GetJourneysByLicenseIdResponse> {
    return await this.rest.generalGet(`/journey/license/${licenseId}`);
  }

  // Find available taxi
  async findAvailableTaxi(data: FindJourneyDto): Promise<GeneralResponse<Journey>> {
    return await this.rest.generalPost('/journey/find', data);
  }

  // Edit service
  async editService(id: number, data: FindJourneyDto): Promise<GeneralResponse<Journey>> {
    return await this.rest.generalPut(`/journey/${id}`, data);
  }

  async getJourneyPrice(data: GetJourneyPriceDto): Promise<GeneralResponse<{ price: string }>> {
    return await this.rest.generalGet(`/journey/price?fromLat=${data.fromLat}&fromLng=${data.fromLng}&toLat=${data.toLat}&toLng=${data.toLng}`);
  }

  // Incidents
  async createIncident(data: CreateIncidentDto): Promise<GeneralResponse<Incident>> {
    return await this.rest.generalPost(`/incidents`, data, true)
  }

  async getIncidentsByUserId(id: number): Promise<GeneralResponse<GeneralResponseData<Incident[]>>> {
    return await this.rest.generalGet(`/incidents`, {
      'where.user.id': id
    });
  }

  // Messages
  async getMessagesByUserId(id: number, page = 1, limit = 10): Promise<GeneralResponse<any>> {
    return await this.rest.generalGet(`/messages?userId=${id}&page=${page}&limit=${limit}`)
  }

  async getChatByJourneyId(id: number, page = 1, limit = 10): Promise<GeneralResponse<MessageMain>> {
    return await this.rest.generalGet(`/messages/journey?id=${id}&page=${page}&limit=${limit}`)
  }

  async getChatsByCurrentUser(): Promise<GeneralResponse<any>> {
    return await this.rest.generalGet(`/messages/chats/current-user`)
  }

  async newMessage(data: NewMessageDto): Promise<GeneralResponse<any>> {
    return await this.rest.generalPost(`/messages`, data);
  }

  async getNotificationsByUserId(id: number): Promise<GeneralResponse<GeneralResponseData<Notification[]>>> {
    const data = {
      page: 1,
      limit: 50,
      'where.recipientID': id
    }
    return await this.rest.generalGet(`/notifications/by-filter`, data);
  }


  // CONFIGURATIONS
  async getConfigurations(id: number): Promise<GeneralResponse<Configuration>> {
    return await this.rest.generalGet(`/configuration/${id}`);
  }

  // CONFIGURATIONS

  async getProvince(id: number): Promise<GeneralResponse<Province>> {
    return await this.rest.generalGet(`/province/${id}`);
  }

  async getAddressByLatLng(lat: number, lng: number): Promise<AddressDetail | null> {
    const apiKey = environment.geoapify;
    const url = `https://api.geoapify.com/v1/geocode/reverse?lat=${lat}&lon=${lng}&apiKey=${apiKey}`;
    const result = await this.simpleGet(url);
    console.log('result?.features?.[0]', result);

    if (result?.features?.[0]?.properties?.address_line1 && result?.features?.[0]?.properties?.address_line2) {
      return { title: result?.features?.[0]?.properties?.address_line1, description: result?.features?.[0]?.properties?.address_line2 };
    }
    return null;
  }

  async getSuggestionsGeoapify(text: string, bias: string): Promise<AddressDetail | null> {
    const apiKey = environment.geoapify;
    const url = `https://api.geoapify.com/v1/geocode/autocomplete?text=${text}&filter=rect:2.259212969172495,39.23653679350352,3.541465270876415,40.00984082021358&bias=rect:${bias}|countrycode:none&format=json&apiKey=${apiKey}`;
    const result = await this.simpleGet(url);
    console.log('getSuggestionsGeoapify', result);

    // if (result?.features?.[0]?.properties?.address_line1 && result?.features?.[0]?.properties?.address_line2) {
    //   return { title: result?.features?.[0]?.properties?.address_line1, description: result?.features?.[0]?.properties?.address_line2 };
    // }
    return null;
  }

  // Simple Get
  async simpleGet(url: string): Promise<any> {
    try {
      const response = await fetch(url);
      const result = await response.json();
      console.log(result);
      // Aquí puedes procesar el resultado como necesites
      return result;
    } catch (error) {
      console.log('Error:', error);
      return null;
    }
  }

}
