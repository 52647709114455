import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  SignInWithApple,
  SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in';
import { Device } from '@capacitor/device';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { TermsComponent } from '@components/terms/terms.component';
import { environment } from '@environments/environment';
import { ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DpStorageService, DpUtilsService } from 'digitup-lib/dist/digitup-lib';
import { FirebaseError } from 'firebase/app';
import { GoogleAuthProvider, OAuthProvider, getAuth, signInWithCredential } from 'firebase/auth';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { LoginProvider } from '../interfaces/user';
import { PushService } from './push.service';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  currentUserObs: BehaviorSubject<boolean> = new BehaviorSubject(true);
  appleConfig: SignInWithAppleOptions = {
    clientId: 'com.mallorcab.client',
    redirectURI: 'http://localhost:8100/login',
    scopes: 'email name',
    state: '',
    // nonce: this.randomString(20),
  };

  // appleConfig: SignInWithAppleOptions = {
  //   clientId: 'com.mallorcab.client',
  //   redirectURI: 'https://mallorcab.com/login',
  //   scopes: 'email name',
  //   state: ''
  // };

  constructor(
    private utils: DpUtilsService,
    private translate: TranslateService,
    private storage: DpStorageService,
    private nav: NavController,
    private auth: AngularFireAuth,
    private modal: ModalController,
    private rest: RestService,
    private push: PushService
  ) {
    setTimeout(() => {
      this.initSocialAuth();
    }, 2000);
  }

  randomString(length: number) {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  async getCurrentUser() {
    return await this.auth.currentUser;
  }

  async getToken() {
    const currentUser = await this.getCurrentUser();
    if (currentUser) {
      return currentUser.getIdToken();
    } else {
      return null;
    }
  }

  async initSocialAuth() {
    console.log('initSocialAuth!!!!!');
    const device = await Device.getInfo();
    if (device.platform !== 'android') {
      GoogleAuth.initialize({
        clientId: device.platform === 'ios' ? environment.googleAuthApiKeyIos : environment.googleAuthApiKeyWeb,
        scopes: ['profile', 'email'],
        grantOfflineAccess: true,
      });
      console.log('After init!!!!!', device.platform);
    }
  }

  async googleAuth() {
    try {
      const credential = await this.getGoogleCredentials();
      if (credential) {
        const result = await this.firebaseAuth({ credential }, 'google');
        if (result) {
          console.log('Autenticación con Google exitosa');
        } else {
          console.error('Error en la autenticación con Google');
        }
      }
    } catch (error) {
      console.error('Error general en la autenticación con Google', error);
    }
  }

  async appleAuth() {
    const credential = await this.getAppleCredentials();
    if (credential) {
      await this.firebaseAuth(credential, 'apple');
    }
  }

  async loginWithEmailAndPassword(email: string, password: string) {
    try {
      const resp = await this.auth.signInWithEmailAndPassword(email, password);
      const userId = resp?.user?.uid;
      await this.enterToApp();

      return userId;
    } catch (error: any) {
      console.error('error loginWithEmailAndPassword', error);
      await this.utils.showToast({ header: '¡Error!', message: await (this.translate.instant('errorMessages.firebase.' + error.code)), cssClass: 'danger' });
      return false;
    }
  }

  async enterToApp() {
    await (await this.auth.currentUser)?.getIdToken(true);
    await this.nav.navigateRoot('/');
    await this.deleteStorageData();
    setTimeout(async () => {
      await this.push.checkFcmToken();
    }, 3000);
  }

  async registerWithEmailAndPassword(data: {
    name: string;
    email: string;
    password: string;
  }) {
    console.log('registerWithEmailAndPassword', data);
    try {
      const result = await this.rest.registerRegularUser(data);
      console.log('result', result);
      if (result.ok) {
        await this.loginWithEmailAndPassword(data.email, data.password);
      }
    } catch (error: any) {
      console.log(error);
      await this.utils.showToast({ message: this.translate.instant(`errorMessages.firebase.${error.code}`), cssClass: 'danger', header: '¡Error!' });
    }
  }

  async openTermsModal(general = false, comunications = false) {

    const modal = await this.modal.create({
      component: TermsComponent,
      cssClass: 'terms',
      componentProps: {
        general,
        comunications
      }
    });

    await modal.present();
    const result = await modal.onWillDismiss();
    console.log(result.data);

    if (result?.data) {
      return result.data;
    } else {
      return undefined;
    }
  }

  async logout() {
    try {
      console.log('logout user');

      // this.firestore.stopLivereload();
      await this.deleteStorageData();
      await this.auth.signOut();
      await GoogleAuth.signOut();
      this.currentUserObs.next(true); // TODO: For what?
    } catch (error: any) {
      console.log(error);
      this.utils.showToast({ message: await this.translate.instant(`errorMessages.firebase.${error.code}`), cssClass: 'danger' });
    }
  }

  async deleteStorageData() {
    await this.storage.deleteItem('formUser');
    await this.storage.deleteItem('taxt_client_fcm_token');
    await this.storage.deleteItem('taxi_app_current_user');
  }

  async changePassword(email: string, currentPassword: string, newPassword: string) {
    try {
      const resp = await this.auth.signInWithEmailAndPassword(email, currentPassword);
      if (resp) {
        if (this.auth.currentUser) {
          const authResp = await (await this.auth.currentUser)?.updatePassword(newPassword);
          console.log(authResp);
          return true;
        } else {
          console.error('No auth user guardado');
          return false;
        }
      } else {
        console.error('La contraseña actual es incorrecta');
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  observeCurrentUser() {
    return this.currentUserObs.asObservable();
  }

  private async getGoogleCredentials() {
    try {
      const googleUser = await GoogleAuth.signIn();
      console.log('googleUser', googleUser);
      if (googleUser.authentication) {
        const auth = getAuth();
        const credential = GoogleAuthProvider.credential(googleUser.authentication.idToken);
        await signInWithCredential(auth, credential);
        return credential;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error google auth: ', error);
      return null;
    }
  }

  private async getAppleCredentials() {
    console.log('getAppleCredentials', this.appleConfig);
    try {
      const result = await SignInWithApple.authorize(this.appleConfig);
      console.log('result', result);
      const idToken = result.response.identityToken;
      console.log('idToken', idToken);

      // const auth = getAuth();
      const provider = new OAuthProvider('apple.com');
      provider.addScope('email');

      const credential = provider.credential({ idToken });

      // console.log('credential', credential);
      // await signInWithCredential(auth, credential);

      return { credential, userData: result.response };
    } catch (error) {
      console.error('Error Apple auth: ', error);
      return null;
    }
  }

  private async firebaseAuth(data: { credential?: any; userData?: any }, loginType: LoginProvider) {
    const credential = data?.credential;
    try {
      if (!credential) {
        throw new Error('Missing credential');
      }

      console.log('firebaseAuth', data);
      const result = await this.auth.signInWithCredential(credential);
      if (!result) {
        throw new Error('Authentication failed');
      }

      console.log('result', result);
      console.log('result?.additionalUserInfo', result?.additionalUserInfo);
      let existsUid = true;
      if (result.user?.uid) {
        const resultExists = await this.rest.existsUid(result.user?.uid);
        console.log('resultExists', resultExists);
        if (resultExists.ok) {
          existsUid = resultExists.response;
        }
      }

      if (existsUid) {
        // Acciones para usuarios existentes
        await this.enterToApp();
        return true;
      } else {
        // Acciones para nuevos usuarios
        const terms = await this.openTermsModal();
        if (terms) {
          let name = result?.user?.displayName;

          if (!name && (data.userData.givenName || data.userData.familyName)) {
            name = `${data?.userData?.givenName || ''} ${data?.userData?.familyName || ''}`;
          }

          const newUserObj = {
            name: name || 'Nombre',
            email: result?.user?.email ? result.user.email : (data?.userData?.email ? data.userData.email : ''),
            image: result.user?.photoURL || 'image',
            language: 'es',
            general: terms.general,
            comunication: terms.comunication
          };

          try {
            const resp = await this.rest.registerSocialUser(newUserObj);
            if (resp) {
              await this.enterToApp();
              return true;
            } else {
              throw new Error('Registration failed');
            }
          } catch (error) {
            console.error('Error during new user registration', error);
            const user = await this.auth.currentUser;

            if (user) {
              await user.delete();
            }

            return false;
          }
        } else {
          console.error('Usuario no ha aceptado los terminos');
          const user = await this.auth.currentUser;
          if (user) {
            await user.delete();
          }
          return false;
        }
      }
    } catch (error) {
      console.error('Error during authentication', error);

      if (error instanceof FirebaseError && error?.code === 'auth/account-exists-with-different-credential') {
        // Manejar la situación de credenciales diferentes según tu lógica específica
        return false;
      }

      return false;
    }
  }


}
