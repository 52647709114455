<div class="preparing_trip_box">
  <div class="top_side">
    <ng-container *ngIf="journeyStatus === 'NEW'">
      <div class="left_side">
        <div class="label-s-regular">{{'preparing_trip.searching' | translate}}</div>
        <div class="heading-m">{{'preparing_trip.preparing' | translate}}</div>
      </div>
    </ng-container>
    <ng-container *ngIf="journeyStatus === 'DRIVER_SEARCHING' || journeyStatus === 'DRIVER_FOUND'">
      <div class="left_side">
        <div class="label-s-regular">{{'preparing_trip.searching' | translate}}</div>
        <div class="heading-m">{{'preparing_trip.location' | translate}}</div>
      </div>
    </ng-container>
    <ng-container *ngIf="journeyStatus === 'DRIVER_CONFIRMED'">
      <div class="left_side">
        <div class="label-s-regular">{{'preparing_trip.driver_found' | translate}}</div>
        <div class="heading-m">{{ 'preparing_trip.license_number' | translate:{num:
          currentJourney?.driver?.license?.taxiNumber || ''} }}</div>
      </div>
    </ng-container>

    <div class="image_box">
      <!-- <ion-icon name="dp-car"></ion-icon> -->
      <img src="/assets/images/dp-car.png">
      <ng-container *ngIf="journeyStatus === 'DRIVER_CONFIRMED'">
        <div class="check">
          <ion-icon name="dp-check"></ion-icon>
        </div>
      </ng-container>
    </div>
  </div>
  <ion-progress-bar type="indeterminate"></ion-progress-bar>
</div>