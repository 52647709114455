import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';
import { Observable, from, mergeMap } from 'rxjs';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService) { }

  intercept(requestToHandle: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skipIntercept = requestToHandle.headers.has('skipAuth') ? (requestToHandle.headers.get('skipAuth') === 'true' ? true : false) : null;
    let headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    if (environment.production) {
      headers = headers.append('dp-encoding', 'gzipSync');
    }

    if (!requestToHandle.url.includes('assets/i18n/')) {
      requestToHandle = requestToHandle.clone({
        url: `${environment.apiMainUrl}${requestToHandle.url}`
      });
    }

    if (skipIntercept) {
      const noAuth = requestToHandle.clone({ headers });
      return next.handle(noAuth);
    }

    return from(this.auth.getToken()).pipe(mergeMap(
      token => {
        if (!skipIntercept && !token) {
          const noAuth = requestToHandle.clone({ headers });
          return next.handle(noAuth);
        } else {
          // Create headers and set token header
          headers = headers.append('Authorization', `Bearer ${token}`);
          const authReq = requestToHandle.clone({ headers });

          return next.handle(authReq);
        }
      }
    ));
  }
}
