export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyD4whYZk6F97tIkSQVdvezod2XVISah6GY',
    authDomain: 'taxi-mallorca-develop.firebaseapp.com',
    projectId: 'taxi-mallorca-develop',
    storageBucket: 'taxi-mallorca-develop.appspot.com',
    messagingSenderId: '214034210798',
    appId: '1:214034210798:web:717ecede6d1adba0f138ff',
    measurementId: 'G-MRFRSEQWTQ'
  },
  language: {
    defaultLang: 'es',
    available: ['es', 'en']
  },
  apiMainUrl: 'https://main-develop.mallorcab.com',
  socketUrl: 'wss://main-develop.mallorcab.com/',
  frontUrl: 'https://mallorcab.com',
  googleAuthApiKeyIos: 'AIzaSyBTi7Wheetr4R7_W08Fb8oDA1oVZGCvr84',
  googleAuthApiKeyWeb: '214034210798-hbporal9vhdbqqqn9id7bqnb6gj38e02.apps.googleusercontent.com',
  mapsApiKey: 'AIzaSyA8slxGupKW_cfCOTjPYnhnsybpmCE1_pY',
  stripe_apikey: 'pk_test_51NVWKoGpMnctcdNyCnKlN14PqzSRSwbwEdgCSzJ0xujwRAs3XJgZWYboL4gJU0bXiiqedl1GL1LJfUiM6HV1sMv7001qLY0z4Z',
  configurationId: 1,
  geoapify: '38c1835e313046e39800f6989de2c943'
};