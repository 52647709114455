import { Component, OnInit } from '@angular/core';
import { Journey, JourneyStatus } from '@interfaces/journey';
import { TripParams } from '@interfaces/trip-params';
import { User } from '@interfaces/user';
import { ModalController } from '@ionic/angular';
import { JourneyWatchService } from '@services/journey-watch.service';
import { RestService } from '@services/rest.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-preparing-trip',
  templateUrl: './preparing-trip.component.html',
  styleUrls: ['./preparing-trip.component.scss'],
})
export class PreparingTripComponent implements OnInit {

  private journeySubscription!: Subscription | null;

  tripParams!: TripParams;
  journeyStatus!: JourneyStatus;
  currentJourney?: Journey;

  constructor(
    private modalController: ModalController,
    private journeyWatchService: JourneyWatchService,
    private rest: RestService
  ) {
    this.journeySubscription = this.journeyWatchService.getJourneyCurrentObserable().subscribe(async (currentJourney) => {
      // console.log('PreparingTripComponent');
      console.log('PreparingTripComponent', currentJourney);
      let currentUser: User;
      const result = await this.rest.getCurrentUser();
      if (result) {
        currentUser = result;
        if (currentJourney && !currentUser.role?.includes('client-hotel')) {
          this.journeyStatus = currentJourney.status;
          this.currentJourney = currentJourney;
          if (['NEW', 'DRIVER_SEARCHING', 'DRIVER_FOUND', 'DRIVER_CONFIRMED', 'PENDING'].includes(this.journeyStatus)) {
            this.nextStep(this.journeyStatus)
          }
        }
      }
    })
  }

  ngOnInit() {
    if (!this.journeyStatus) {
      this.journeyStatus = 'NEW';
      this.nextStep(this.journeyStatus)
    }
  }

  nextStep(journeyStatus: JourneyStatus) {
    if (journeyStatus === 'NEW') {
      this.startNewJourney();
    } else if (journeyStatus === 'DRIVER_CONFIRMED' || journeyStatus === 'PENDING') {
      this.handleDriverConfirmed();
    }
  }

  startNewJourney() {
    setTimeout(() => {
      this.journeyStatus = 'DRIVER_SEARCHING';
    }, 3000);
  }

  async handleDriverConfirmed() {
    this.journeySubscription?.unsubscribe();
    console.log('await this.modalController.getTop()', await this.modalController.getTop());
    await this.modalController.dismiss('DRIVER_CONFIRMED');
  }

}
