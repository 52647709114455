import { inject } from '@angular/core';
import { Routes } from '@angular/router';
import { AuthGuard } from '@guards/auth.guard';
import { OnboardingGuard } from '@guards/onboarding.guard';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.routes').then((m) => m.routes),
    canActivate: [
      () => inject(AuthGuard).canActivateFn()
    ]
  },
  {
    path: 'onboarding',
    loadComponent: () => import('./pages/onboarding/onboarding.page').then(m => m.OnboardingPage)
  },
  {
    path: 'pre-login',
    loadComponent: () => import('./pages/auth/pre-login/pre-login.page').then(m => m.PreLoginPage),
    canActivate: [
      () => inject(OnboardingGuard).canActivateFn(),
    ]
  },
  {
    path: 'login',
    loadComponent: () => import('./pages/auth/login/login.page').then(m => m.LoginPage),
    canActivate: [
      () => inject(OnboardingGuard).canActivateFn(),
    ]
  },
  {
    path: 'register',
    loadComponent: () => import('./pages/auth/register/register.page').then(m => m.RegisterPage),
    canActivate: [
      () => inject(OnboardingGuard).canActivateFn(),
    ]
  },
  {
    path: 'recovery',
    loadComponent: () => import('./pages/auth/recovery/recovery.page').then(m => m.RecoveryPage),
    canActivate: [
      () => inject(OnboardingGuard).canActivateFn(),
    ]
  },
  {
    path: 'credit-card',
    loadComponent: () => import('./pages/auth/credit-card/credit-card.page').then(m => m.CreditCardPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'ride:id',
    loadComponent: () => import('./pages/ride/ride.page').then(m => m.RidePage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'chat',
    loadComponent: () => import('./pages/chat/chat.page').then(m => m.ChatPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'books',
    loadComponent: () => import('./pages/books/books.page').then(m => m.BooksPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'logout',
    loadComponent: () => import('./pages/auth/logout/logout.page').then(m => m.LogoutPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'test',
    loadComponent: () => import('./pages/test/test.page').then(m => m.TestPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'my-account',
    loadComponent: () => import('./pages/menu/pages/my-account/my-account.page').then(m => m.MyAccountPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'activity',
    loadComponent: () => import('./pages/menu/pages/activity/activity.page').then(m => m.ActivityPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'wallet',
    loadComponent: () => import('./pages/menu/pages/wallet/wallet.page').then(m => m.WalletPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'messages',
    loadComponent: () => import('./pages/messages/messages.page').then(m => m.MessagesPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'notifications',
    loadComponent: () => import('./pages/menu/pages/notifications/notifications.page').then(m => m.NotificationsPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'rate-app',
    loadComponent: () => import('./pages/menu/pages/rate-app/rate-app.page').then(m => m.RateAppPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'help',
    loadComponent: () => import('./pages/menu/pages/help/help.page').then(m => m.HelpPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'lost-found-goods',
    loadComponent: () => import('./pages/menu/pages/lost-found-goods/lost-found-goods.page').then(m => m.LostFoundGoodsPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'travel-details/:id',
    loadComponent: () => import('./pages/menu/travel-details/travel-details.page').then(m => m.TravelDetailsPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  }, {
    path: 'chat-detail/:id',
    loadComponent: () => import('./pages/chat-detail/chat-detail.page').then(m => m.ChatDetailPage),
    canActivate: [() => inject(AuthGuard).canActivateFn()]
  },
  {
    path: 'terms',
    loadComponent: () => import('./pages/terms/terms.page').then(m => m.TermsPage),
    data: { type: 'terms' }
  },
  {
    path: 'policy',
    loadComponent: () => import('./pages/terms/terms.page').then(m => m.TermsPage),
    data: { type: 'policy' }
  },
  {
    path: 'cookies',
    loadComponent: () => import('./pages/terms/terms.page').then(m => m.TermsPage),
    data: { type: 'cookies' }
  }
];
