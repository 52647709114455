<form [formGroup]="form" class="container" [class.horizontal]="orientation === 'horizontal'">
  <div class="title-container">
    <div class="title" *ngIf="config.title"> {{ config.title | translate }} </div>
    <div class="close" (click)="close()">
      <ion-icon name="dp-close"></ion-icon>
    </div>
  </div>
  <ng-container *ngIf="config.inputs && config.inputs.length">
    <div class="input-container">
      <ng-container *ngFor="let input of config.inputs">
        <ion-item class="input" lines="none" class="input" [class.dark-mode]="darkMode">
          <ion-textarea [formControlName]="input.id" [type]="input.type"
            [attr.disabled]="input.disabled ? input.disabled : null" [placeholder]="input.placeholder | translate">
          </ion-textarea>
          <ion-icon slot="end" *ngIf="input.icon" [name]="input.icon" (click)="getInputEvent(input.id)" appClickVibrate>
          </ion-icon>
        </ion-item>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="config.checkboxes && config.checkboxes.length">
    <div class="checkbox-container">
      <ion-item lines="none" *ngFor="let checkbox of config.checkboxes">
        <ion-checkbox [formControlName]="checkbox.id"></ion-checkbox>
        <ion-label> {{ checkbox.label | translate }} </ion-label>
      </ion-item>
    </div>
  </ng-container>
  <div class="btn-container">
    <ng-container *ngFor="let button of config.buttons">
      <ng-container *ngIf="button.action; then actionButton; else modalButton"></ng-container>

      <ng-template #actionButton>
        <ng-container *ngIf="button.validate; else elseTemplate">
          <ion-button fill="clear" [className]="button.cssClass" [disabled]="form.invalid"
            (click)="button.action ? getData(button) : close()" appClickVibrate>
            <ion-icon *ngIf="button.icon" [name]="button.icon"></ion-icon>
            {{ button.text | translate }}
          </ion-button>
        </ng-container>
        <ng-template #elseTemplate>
          <ion-button fill="clear" [className]="button.cssClass" (click)="button.action ? getData(button) : close()"
            appClickVibrate>
            <ion-icon *ngIf="button.icon" [name]="button.icon"></ion-icon>
            {{ button.text | translate }}
          </ion-button>
        </ng-template>
      </ng-template>

      <ng-template #modalButton>
        <ng-container *ngIf="button.validate; else elseTemplate">
          <ion-button fill="clear" [className]="button.cssClass" [disabled]="form.invalid" (click)="openModal()"
            appClickVibrate>
            <ion-icon *ngIf="button.icon" [name]="button.icon"></ion-icon>
            {{ button.text | translate }}
          </ion-button>
        </ng-container>
        <ng-template #elseTemplate>
          <ion-button fill="clear" [className]="button.cssClass" (click)="openModal()" appClickVibrate>
            <ion-icon *ngIf="button.icon" [name]="button.icon"></ion-icon>
            {{ button.text | translate }}
          </ion-button>
        </ng-template>
      </ng-template>
    </ng-container>
  </div>
</form>